<template>
  <div>
    <template v-if="anyCleaner">
      <div class="row py-2">
        <div class="col-2 offset-sm-1"></div>
        <div class="col-4">Expiration</div>
        <div class="col-5">Team</div>
      </div>
      <div class="row d-flex align-items-center" v-for="team in teams">
        <div class="col-2 offset-sm-1">
          <div class="text-center my-2">
            <img class="img-fluid user-avatar" src="@images/avatar_placeholder.jpg" width="50px" :alt="team.name">
          </div>
        </div>
        <div class="col-4">
          <select v-model="team.expiration" class="custom-select custom-select-sm round-select">
            <option value="0">Don't expire</option>
            <option value="1">1 hour</option>
            <option value="2">2 hours</option>
            <option value="4">4 hours</option>
            <option value="8">8 hours</option>
          </select>
        </div>
        <div class="col-xs-5">
          <button type="button" @click="assign(team)" class="btn btn-mini m-2" :class="btnColor(team)">Assign {{team.name}}</button>
        </div>
        <div class="col-xs-1">
          <client-preference :preference="team.preference" />
        </div>
        <div class="col-11 text-right">
          <cleaner-distance :distance="distances[team.id]" />
        </div>
      </div>
    </template>
    <div v-else class="row">
      <div class="col">
        <div class="text-center m-2">There is no available cleaner</div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import api from '@/clients/api'
import pubsub_mixin from '@/shared/pubsub_mixin'
import CleanerDistance from './cleaner_distance.vue'
import ClientPreference from './client_preference.vue'

export default {
  props: [ 'jobId', 'propertyId' ],
  mixins: [pubsub_mixin],
  components: { CleanerDistance, ClientPreference },
  data() {
    return {
      teams: [],
      distances: {}
    }
  },
  mounted() {
    if (this.jobId) {
      this.getRoster()
    }
  },
  computed: {
    anyCleaner() {
      return this.teams && (this.teams.length > 0)
    }
  },
  methods: {
    assign(team) {
      const params = {
        reservation_id: this.jobId,
        expiration: team.expiration,
        team_id: team.id
      }
      api.createCleaningJob(params).then(response => {
        this.$emit('update')
      }, errors => {
        this.$flasher.errors(errors.response.data.errors)
      })
    },
    btnColor(team) {
      if (team.available) {
        return 'btn-green'
      } else {
        return 'btn-gray'
      }
    },
    getRoster() {
      this.subscribeToUpdates()
      api.getRoster(this.jobId).then(response => {
        this.teams = response.data.teams
      })
    },
    subscribeToUpdates() {
      if (!this.subscribed && this.propertyId) {
        this.fayeSubscribe('/properties/' + this.propertyId + '/cleaner_distance', 'cleaner distance changed', (channel, msg) => {
          if (this.distances[msg.cleaner_id] == null) {
            Vue.set(this.distances, msg.cleaner_id, {})
          }
          Vue.set(this.distances[msg.cleaner_id], 'distance', msg.distance)
          Vue.set(this.distances[msg.cleaner_id], 'homeDistance', msg.home_distance)
          Vue.set(this.distances[msg.cleaner_id], 'at', msg.at)
        })
      }
    }
  },
  watch: {
    jobId(newValue) {
      if (newValue) {
        this.getRoster()
      }
    }
  }
}
</script>
