<template>
  <component v-if="notProcessed" v-bind:is="componentForEvent" :event="event" :visit="visit" :dom-id="domId" :open="last" :show-same-day-badge="showSameDayBadge" />
</template>
<script>
import api from '@/clients/api'
import BaseEvent from './base_event.vue'
import VisitBreakdown from './cleanings/visit_breakdown.vue'
import AssignedBy from './cleanings/assigned_by.vue'
import JobCalledOff from './cleanings/job_called_off.vue'
import BookingUpdate from './bookings/update.vue'
export default {
  extends: BaseEvent,
  components: {
    AssignedBy, JobCalledOff, VisitBreakdown,
    BookingUpdate
  },
  props: {
    domId: {
      type: String,
      required: true
    },
    visit: {
      type: Object,
      required: true
    },
    last: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    componentForEvent() {
      if (this.event.type == 'CleaningJobsJobCalledOff') {
        return 'JobCalledOff'
      }
      if (this.event.type == 'BookingUpdate') {
        return 'BookingUpdate'
      }
      if (this.event.type == 'AssignedBy') {
        return 'AssignedBy'
      }
      return 'VisitBreakdown'
    },
    updateTime() {
      return Date.parse(this.event.time)
    },
    visitStart() {
      return Date.parse(this.visit.start)
    },
    within24Hours() {
      return Date.parse(this.visit.start).addHours(-24)
    },
    sameDay() {
      return Date.parse(this.visit.start).addDays(1).set({ hour: 0, minutes: 0 })
    },
    showSameDayBadge() {
      return this.event.key == "booking.rescheduled" && (this.within24Hours || this.sameDay)
    },
    notProcessed() {
      return !this.event.processed
    }
  },
}
</script>
