<template>
  <div class="row justify-content-around">
    <div class="col-xs">{{ homeDistanceText }}</div><div class="col-xs">{{ distanceText }}</div>
  </div>
</template>
<script>
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)
export default {
  props: {
    distance: {
      type: Object
    }
  },
  computed: {
    distanceText() {
      if (this.distance && this.distance.distance) {
        const timeAgo = new TimeAgo('en-US')
        return this.distance.distance + ' from detected location (' + timeAgo.format(new Date(this.distance.at)) + ')'
      }
    },
    homeDistanceText() {
      if (this.distance && this.distance.homeDistance) {
        return this.distance.homeDistance + ' from home'
      }
    }
  },
}
</script>
