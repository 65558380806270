<template>
  <div class="col-auto my-1">
    <spinner :show="loading" :use-modal="false" message="Loading"></spinner>
    <div v-for="note in notes" class="alert alert-warning" role="alert">{{note.text}}</div>
  </div>
</template>
<script>
import Spinner from '@/shared/spinner.vue'
import api from '@/clients/api'
export default {
  props: {
    propertyId: {
      type: Number,
      required: true
    }
  },
  components: { Spinner },
  data() {
    return {
      loading: true,
      notes: []
    }
  },
  mounted() {
    this.loadNotes()
  },
  methods: {
    loadNotes() {
      this.loading = true
      api.getPropertyNotesForVisit(this.propertyId).then(response => {
        this.notes = response.data
        this.loading = false
      }, (error) => {
        this.loading = false
      })
    }
  }
}
</script>
