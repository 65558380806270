<template>
  <div class="box-wrapper mt-4">
    <div class="row justify-content-center breakdown-sec mb-2">
      <visit-breakdown :visit="changedVisit" :dom-id="domId" :prefix-text="details" :open="last">
        <template v-slot:actions>
          <div v-if="canApprove" class="row justify-content-center">
            <b-button variant="success" @click="approve">Approve</b-button>
            <b-button variant="danger" @click="reject">Reject</b-button>
          </div>
        </template>
      </visit-breakdown>
    </div>
  </div>
</template>
<script>
import BaseEvent from '../base_event.vue'
import VisitBreakdown from '@/shared/visit_breakdown.vue'
import sharedApi from '@/shared/api'
export default {
  extends: BaseEvent,
  props: {
    domId: {
      type: String,
      required: true
    },
    last: {
      type: Boolean,
      default: false
    },
    visit: {
      type: Object,
      required: true
    }
  },
  components: { VisitBreakdown },
  computed: {
    canApprove() {
      if (this.event.processed || this.event.approval) {
        return false
      }
      if (this.requestedByClient) {
        return this.isStaff || this.isCleaner
      }
      if (this.requestedByCleaner) {
        return this.isStaff || this.isClient
      }
      return false
    },
    changedVisit() {
      return {
        extras: this.event.extras,
        extra_charges: this.event.extra_charges,
        cleaning_charges: this.visit.cleaning_charges,
        pretty_requested_cleaning: this.visit.pretty_requested_cleaning,
        total_cleaning_charges: this.event.total_cleaning_charges
      }
    },
    details() {
      if (this.event.approval) {
        return 'The requested changes approved by ' + this.actor + ', New '
      }
      if (this.event.rejection) {
        return 'The requested changes rejected by ' + this.actor + ', New '
      }
      return 'Change requested by ' + this.actor + ', New '
    },
    requestedByClient() {
      return this.event.actorType == 'Client'
    },
    requestedByCleaner() {
      return this.event.actorType == 'Cleaner'
    },
    requestedByStaff() {
      return !this.requestedByClient && !this.requestedByCleaner
    },
  },
  methods: {
    approve() {
      this.loading = true
      sharedApi.approveChangeRequest(this.event.id).then(response => {
        this.$emit('reload')
      }, error => {
        this.loading = false
        this.$flasher.error(error.response.data.errors)
      })
    },
    reject() {
      this.loading = true
      sharedApi.rejectChangeRequest(this.event.id).then(response => {
        this.$emit('reload')
      }, error => {
        this.loading = false
        this.$flasher.error(error.response.data.errors)
      })
    }
  }

}
</script>
