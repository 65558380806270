<template>
  <div class="col-sm-4 my-1">
    <label class="checkbox-set" id="tooltip-button-1">Lock Visit &nbsp;
      <input type="checkbox" v-model="changedManual" @change="updateReservation" />
      <span class="checkmark checkmark-blue"></span>
    </label>
    <b-tooltip target="tooltip-button-1" placement="auto">
      You can prevent the system to change this visit by locking it.
      <strong>Unlocked visits will be updated by the system during rescheduling or when you update the related property's details.</strong>
    </b-tooltip>
  </div>
</template>
<script>
import api from '@/clients/api'
export default {
  data() {
      return {
        changedManual: this.manual
      }
    },
    props: {
      eventId: {
        type: Number,
        required: true,
      },
      manual: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      updateReservation() {
        var params = {
          manual: this.changedManual
        }
        api.updateReservation(this.eventId, params).then(response => {
          this.changedManual = response.data.manual
          this.$emit('reservation-updated')
        }, response => {
          this.$flasher.error(response.body)
        });
      }
    }
}
</script>
