<template>
  <div v-if="visit" class="pictures">
    <spinner :show="loading" :use-modal="false" message="Loading"></spinner>
    <div v-if="!loading" class="box-wrapper mt-4">
      <h4>Before Pictures</h4>
      <template v-if="canUpload">
        <p class="text-center">Max. {{ maxImages }} images</p>
        <image-uploader
          class="before-images"
          :attr="attrForField"
          :max-files="maxImages"
          :url="url"
          :process-result="processUpload"
          :form-data="beforeUploadFormData"></image-uploader>
      </template>
      <div class="row row-cols-1 row-cols-md-2"><job-image v-for="image in beforeImages" :key="image.id" :image="image" @reload="loadJobImages" /></div>
      <h4>After Pictures</h4>
      <template v-if="canUpload">
        <p class="text-center">Max. {{ maxImages }} images</p>
        <image-uploader
          class="after-images"
          :attr="attrForField"
          :max-files="maxImages"
          :url="url"
          :process-result="processUpload"
          :form-data="afterUploadFormData"></image-uploader>
      </template>
      <div class="row row-cols-1 row-cols-md-2"><job-image v-for="image in afterImages" :key="image.id" :image="image" @reload="loadJobImages" /></div>
    </div>
  </div>
</template>
<script>
import api from '@/clients/api'

import Spinner from '@/shared/spinner.vue'
import JobImage from './job_image.vue'
import ImageUploader from '@/shared/uploaders/image.vue'

export default {
  data() {
    return {
      ourLoading: false,
      imageIds: [],
      maxImages: 10,
      afterImages: [],
      beforeImages: []
    }
  },
  props: {
    visit: {
      type: Object
    },
    mainLoading: {
      type: Boolean
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Spinner, JobImage, ImageUploader
  },
  computed: {
    loading() {
      return this.ourLoading || this.mainLoading
    },
    attrForField() {
      return {
        name: 'job_image[image]',
        key: 'id'
      }
    },
    canUpload() {
      return this.visit.has_job && (this.isStaff || this.isCleaner)
    },
    jobId() {
      return this.visit.main_job_id
    },
    url() {
      return "/api/job_images"
    },
    afterUploadFormData() {
      return { 'job_image[cleaning_job_id]': this.jobId, 'job_image[group]': 'after' }
    },
    beforeUploadFormData() {
      return { 'job_image[cleaning_job_id]': this.jobId, 'job_image[group]': 'before' }
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    loadJobImages() {
      this.ourLoading = true
      api.getJobImagesForVisit(this.visit.id).then(response => {
        this.beforeImages = response.data.before_images
        this.afterImages = response.data.after_images
        this.ourLoading = false
      }, error => {
        this.ourLoading = false
        this.$flasher.error(error.response.data.errors)
      })
    },
    processUpload(result, key) {
      this.loadJobImages()
      return result
    }
  },
  mounted() {
    if (this.visit) {
      this.loadJobImages()
    }
  },
  watch: {
    visit(newValue) {
      if (newValue) {
        this.loadJobImages()
      }
    }
  }
}
</script>
