<template>
  <b-icon v-if="hasPreference" :icon="icon" :variant="variant" font-scale="2" v-b-tooltip.hover :title="title"></b-icon>
</template>
<script>
import { BIcon, BIconExclamationCircleFill, BIconPersonCheckFill } from 'bootstrap-vue'

export default {
  props: {
    preference: {
      type: String
    }
  },
  components: {
    BIcon,
    BIconExclamationCircleFill,
    BIconPersonCheckFill
  },
  computed: {
    disliked() {
      return this.preference == 'dislike'
    },
    hasPreference() {
      return this.preference != null
    },
    icon() {
      return this.disliked ? 'exclamation-circle-fill' : 'person-check-fill'
    },
    title() {
      return this.disliked ? 'Client dislikes this cleaner' : 'Client likes this cleaner'
    },
    variant() {
      return this.disliked ? 'danger' : 'success'
    }
  },
}
</script>
