<template>
  <div class="row">
    <div class="col-12">
      <div v-if="!callingOff" class="row justify-content-between">
        <div class="col-auto">
          <a v-if="canCallOff" @click.prevent="callOffJob" href="#"><i class="fas fa-user-slash text-danger"></i> Call Off</a>
        </div>
        <div class="col-auto"><a v-if="canAssignMore" @click.prevent="assignCleaner" href="#"><i class="fas fa-plus-circle"></i> Cleaner</a></div>
      </div>
      <template v-if="loading"><spinner :show="loading" :use-modal="false" message="Loading"></spinner></template>
      <template v-else>
        <template v-if="showMode">
          <div class="accordion" id="team">
            <div v-for="assignment in assignments" :key="assignment.id" class="card-cleaner-info card row">
              <div class="card-header" :id="'header' + assignment.job_id">
                <h6 class="mb-0">
                  <a href="#" data-toggle="collapse" :data-target="'#collapse' + assignment.job_id" aria-expanded="false" :aria-controls="'collapse' + assignment.job_id">
                    {{ assignment.assigned_to }} <job-status-with-eta :job="assignment"></job-status-with-eta> {{ assignment.cancellation_info }}
                  </a>
                </h6>
              </div>
              <div :id="'collapse' + assignment.job_id" class="collapse" :aria-labelledby="'header' + assignment.job_id" data-parent="#team">
                <div class="card-body">
                  <job-management-buttons :job-id="assignment.job_id" :readonly="readonly" @update="onUpdate" :require-confirm-unassign="localRequireConfirmUnassign" :can-update="canUpdate" />
                  <h6 v-if="assignment.cancelled_by" class="text-danger text-center">{{ assignment.cancelled_by }} cancelled on {{ assignment.cancelled_at }}</h6>
                </div>
              </div>
            </div>
          </div>
          <template v-for="status in clientStatuses">
            <span class="mt-2 mr-2" :class="status.css">{{ status.text }}</span>
          </template>
          <p class="text-center" v-if="canUpdate && assignments && assignments.length == 0"><br>
            <a @click.prevent="assignCleaner()" class="btn btn-mini btn-primary" href="#">Assign Team</a>
          </p>
        </template>
        <template v-if="assignmentMode">
          <h6 v-if="testVisit" class="alert alert-info text-center" role="alert">Test Visit: Only cleaners in preapproval status listed</h6>
          <assignment-form :job-id="reservationId" :property-id="locationId" @update="onUpdate">
            <template slot="buttons">
              <button @click="toShowMode" class="btn btn-mini btn-gray">Cancel</button>
            </template>
          </assignment-form>
        </template>
        <template v-if="callingOff">
          <job-calling-off-form :job-id="firstJob.job_id" :call-off-amount="firstJob.call_off_amount" @done="onUpdate" @cancel="toShowMode"></job-calling-off-form>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import api from '@/clients/api'
import AssignmentForm from '@/grid/assignment_form.vue'
import JobCallingOffForm from '@/grid/job_calling_off_form.vue'
import JobManagementButtons from '@/shared/job_management_buttons.vue'
import JobStatusWithEta from '@/shared/job_status_with_eta.vue'
import Spinner from '@/shared/spinner.vue'
import permissions_mixin from '@/shared/permissions_mixin'

export default {
  props: {
    reservationId: {
      required: true
    },
    locationId: {
      required: true
    },
    permissions: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      required: true
    },
    requireConfirmUnassign: {
      type: Boolean,
      required: true
    },
    testVisit: {
      type: Boolean,
      default: true
    },
    clientStatuses: {
      type: Array,
      required: true
    }
  },
  components: { AssignmentForm, JobCallingOffForm, JobManagementButtons, JobStatusWithEta, Spinner },
  mixins: [ permissions_mixin ],
  data() {
    return {
      assignments: null,
      mode: '',
      loading: true,
      localRequireConfirmUnassign: this.requireConfirmUnassign
    }
  },
  mounted() {
    if (this.reservationId) {
      this.getAssignmentInfo()
    }
  },
  computed: {
    canAssignMore() {
      return this.canUpdate && this.assignments && this.assignments.length > 0
    },
    cancelled() {
      if (this.firstJob) {
        return this.firstJob.cancelled
      }
    },
    canCallOff() {
      if (this.firstJob) {
        return this.firstJob.can_call_off
      }
    },
    callingOff() {
      return this.mode == 'callOffJob'
    },
    firstJob() {
      if (this.assignments) {
        return this.assignments[0]
      }
    },
    assignmentMode() {
      return this.mode == 'assignment'
    },
    showMode() {
      return this.readonly || (this.mode == '')
    },
  },
  methods: {
    toShowMode() {
      this.mode = ''
      this.$emit('cancel-assigning')
    },
    callOffJob() {
      this.mode = 'callOffJob'
    },
    cancelJob() {
      this.mode = 'cancelJob'
    },
    assignCleaner() {
      this.mode = 'assignment'
      this.$emit('assigning')
    },
    getAssignmentInfo() {
      this.loading = true
      api.getReservationAssignment(this.reservationId).then(response => {
        this.assignments = response.data
        this.localRequireConfirmUnassign = this.assignments.length > 0
        this.loading = false
      })
    },
    jobStatusCss(status_color) {
      return 'text-' + status_color
    },
    onUpdate() {
      this.mode = ''
      this.$emit('reload')
      this.getAssignmentInfo()
    }
  },
  watch: {
    reservationId(newId) {
      if (newId) {
        this.getAssignmentInfo(newId)
      }
    },
    readonly(newValue) {
      if (newValue === true) {
        this.mode = ''
      }
    },
    mode(newMode) {
      this.$emit('update-lock', newMode != '')
    }
  }
}
</script>
