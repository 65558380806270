<template>
  <div class="schedule-form">
    <h4 class="text-center my-2" v-if="schedule_frequency">
      <schedule-summary @show-options="showRecurrenceOptions = true" :showOptions="!showRecurrenceOptions" :enabled="!singleEventMode" v-bind="summaryParams" />
    </h4>
    <input type="hidden" id="status">
    <div class="px-1">
      <recurrence-options v-if="showRecurrenceOptions" :frequency="schedule_frequency" :interval="schedule_interval" @update:frequency="schedule_frequency = $event" @update:interval="schedule_interval = $event"></recurrence-options>
      <date-field v-if="showDateField" @update:starts="onStartsChange" :starts="start" :min-date="minDate" :location-id="event.location_id"></date-field>
      <weekly-repeat-on v-if="showWeeklyRepeatOnOptions && pickedDayInfo" :picked-day-info="pickedDayInfo" @update:repeat_on_weekday="onUpdateRepeatOnWeekday"></weekly-repeat-on>
    </div>

    <repeat-on-day-picker v-if="showRepeatDayPicker" :datetime="start" @repeat-on-day-picked="onRepeatOnDayPicked" @update:repeat-by="schedule_repeat_by = $event" :repeat-by="schedule_repeat_by" :picked-day-info="pickedDayInfo"></repeat-on-day-picker>

    <div v-if="hasSkips" class="row text-center mt-2">
      <div class="alert alert-danger" role="alert">
        Warning: your visits may not reapeat in a monthly basis, please confirm
      </div>
      <div class="col-12 form-group">
        <label class="checkbox-set">Confirm schedule that might skips months
          <input type="checkbox" v-model="skipsConfirmed" />
          <span class="checkmark checkmark-blue"></span>
        </label>
      </div>
    </div>

    <my-form v-if="isStaff" :start-in-edit-mode="true">
      <template v-slot:default="{invalid, valid, errors, formIsReadonly }">
        <div class="row">
          <div class="col">
            <reason-field v-model="reason" name="reason" label="Why do you need to change the schedule?" rules="required|min:5" />
          </div>
        </div>
      </template>

      <template v-slot:edit-buttons="{ invalid, validated }">
          <button type="submit" class="btn btn-mini btn-green my-3">Reschedule</button>
          <button type="button" class="btn btn-mini btn-gray my-3" @click="$emit('cancel')">Cancel</button>
      </template>
    </my-form>

    <div v-if="isClient" class="my-3">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="text-center mt-2">
          <button type="button" class="btn btn-mini btn-green my-0" @click="save()">Reschedule</button>
          </div>
        <div class="text-center mt-2">
          <button type="button" class="btn btn-mini btn-gray my-0" @click="$emit('cancel')">Cancel</button>
        </div>
      </div>
    </div>
    <h6 v-if="showWarningAboutNotification" class="text-center mt-2">Go to <a href="/changes">notification page</a> to cancel email</h6>
    <h6 v-if="event.pending && isClient" class="text-center mt-2">We will get back to you in less than 24 hours to confirm your booking request <br>or contact us at <strong class="line-break">{{companyPhone}}</strong> for further assitance</h6>
  </div>
</template>
<script>
import ScheduleCommonMixin from './schedule_common_mixin.js'
import company_phone_mixin from '@/shared/company_phone_mixin'
import MyForm from '@/shared/base_form.vue'
export default {
  components: { MyForm },
  mixins: [ScheduleCommonMixin,company_phone_mixin],
  mounted() {
    this.initEvent(this.event)
  },
  methods: {
    submit() {
      this.save()
    }
  }
}
</script>
