<template>
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col-sm text-center">
          <h6>The cleaner cancelled the job and uploaded the following proof:</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-sm text-center">
          <a :href="imageUrl" target="_blank"><img :src="thumbUrl"></a>
          <button class="btn btn-danger" :disabled="loading" @click="$emit('approve')">Approve Cancellation</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm text-center">
          <h6>Or change the state of the job using the buttons.</h6>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/clients/api'
export default {
  props: {
    job: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    imageUrl() {
      return this.job.cancellation_image_url
    },
    isCancelledByCleaner() {
      return true
    },
    thumbUrl() {
      return this.job.cancellation_image_thumb_url
    },
  }
}
</script>
