<template>
  <div class="box-wrapper px-2 mt-3 col-12">
    <template v-if="loading" >
      <spinner />
    </template>
    <template v-else>
      <tip-form
        :can-update="canUpdate"
        :reservation-id="eventId"
        :job="job"
        :total-cents="totalCents"
        :on-light-theme="true"
        :cents="tipCents"
        :percentage="tipPercentage"
        :save-tip="saveTip"
        @update="getTips"
      ></tip-form>
    </template>
  </div>
</template>
<script>
import api from '@/clients/api'
import adminApi from '@/admins/api'
import TipForm from '@/shared/tip_form.vue'
import Spinner from '@/shared/non_modal_spinner.vue'
export default {
  props: {
    eventId: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  components: {
    TipForm,
    Spinner
  },
  data() {
    return {
      canUpdate: false,
      saveTip: false,
      totalCents: 0,
      tipCents: 0,
      tipPercentage: 0,
      job: null
    }
  },
  mounted() {
    this.getTips()
  },
  methods: {
    getTips() {
      api.getTipsForVisit(this.eventId).then((response) => {
        this.canUpdate = response.data.can_update
        this.tipCents = response.data.tip_cents
        this.totalCents = response.data.total_cents
        this.tipPercentage = response.data.tip_percentage
        this.saveTip = response.data.save_tip
        this.job = response.data.job
      })
    }
  }
}
</script>
