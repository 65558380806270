<template>
  <div class="col-sm-4 my-1">
    <label class="checkbox-set" id="tooltip-button-1">Test Visit &nbsp;
      <input type="checkbox" v-model="changedTest" @change="updateReservation" />
      <span class="checkmark checkmark-blue"></span>
    </label>
    <b-tooltip target="tooltip-button-1" placement="auto">
      You can mark a visit as test cleaning for new cleaners. The test visits will work exactly the non-test visits, but only new cleaners claim those.
    </b-tooltip>
  </div>
</template>
<script>
export default {
  data() {
      return {
        changedTest: this.test
      }
    },
    props: {
      eventId: {
        type: Number,
        required: true,
      },
      test: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      updateReservation() {
        this.$emit('update:reservation', {
          test: this.changedTest
        })
      }
    }
}
</script>
