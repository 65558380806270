<template>
  <li v-if="info">
    <div class="job timeline-info-container mt-3 mb-4 px-4" :class="indentClass">
      <div class="timeline-inner-content px-4">
        <div class="row">
          <div class="col-sm-9">
            <h4 class="mb-0 timeline-title">{{info.event}}</h4>
            <p v-if="info.details">{{info.details}}</p>
            <p v-if="info.subject">Subject: {{info.subject}}</p>
            <small>{{info.at}} by {{info.actor}}</small>
          </div>
          <div class="col-sm-3">
            <template v-for="action in actions">
              <b-button v-if="action.allowed" variant="link" size="sm" @click="doAction(action)" :class="action.color">{{action.name}}</b-button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>
<script>
import Vue from 'vue'

export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    activeUserId() {
      return this.activeUser.class + '$' + this.activeUser.id
    },
    actions() {
      return this.event.actions
    },
    actor() {
      if (this.event.hideActor) {
        return
      }
      if (this.activeUserId == this.info.actorId) {
        if (this.event.usePossessive) {
          return 'Your'
        }
        return 'You'
      }
      return this.event.usePossessive ? `${this.event.actor}'s` : this.event.actor
    },
    colorCss() {
      return this.event.info.color
    },
    indentClass() {
      return 'offset-sm-' + this.event.indent
    },
    info() {
      return this.event.info
    },
    canRetry() {
      return true
      return this.event.indent == 0
    },
    title() {
      return this.info.event
    },
    subtitle() {
      return 'By ' + this.actor + ' at ' + this.info.at
    },
    recipient() {
       return this.event.recipient
    },
    details() {
      return this.event.details
    },
    preview() {
      return this.event.preview
    }
  },
  methods: {
    doAction({ action, event }) {
      if (this[action.do]) {
        console.log('base_event, doAction', action)
        this[action.do](event)
      } else {
        this.$emit('do-action', { action: action, event: event });
      }
    },
    goToUrl(action) {
      console.log('base event, goToUrl', action)
      window.open(action.url, '_blank')
    },
    new_reservation(event) {
      window.location = '/properties/' + event.propertyId + '/book?event_id=' + event.id
    },
    retry() {
      this.loading = true
      this.$http.put('/events/' + this.event.event_id + '/retry').then(response => {
       }, (error) => {
        this.loading = false
        this.$flasher.error(error.data.errors)
      })
      //window.open(this.event.visit.url, '_blank').focus()
    },
  }
}
</script>
