<template>
  <my-form :start-in-edit-mode="canUpdate" :force-edit-mode="true" :show-cancel="false" submitLabel="Save Tip" success-message="Tip added" :readonly="loading || !canUpdate">
    <template v-slot:default="{ invalid, valid, formIsReadonly }">
      <spinner :show="loading" />
      <template v-if="!loading">
        <template v-if="canUpdate">
          <h4 v-if="cleaner" class="text-center font-weight-light">Add a tip for {{cleaner}}</h4>
          <h6 v-if="propertyHasTip" class="text-center mt-2">Showing tip saved with the property</h6>
          <div class="text-center my-3">
            <div class="tips-button-group">
              <b-button v-for="percentage in [10,15,20]" @click="toggleTip(percentage)" :class="tipPercentage === percentage ? 'active': ''" class="btn-round" :key="percentage" :variant="btnVariant">{{percentage}} <i class="fal fa-percent"></i> </b-button>
              <h6 v-if="tipPercentage">Amount: ${{calculatedTip}}</h6>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6 col-sm-8">
              <tip-field label="Or enter a custom amount below" v-model="customTip" name="tip"></tip-field>
            </div>
          </div>
          <div v-if="RecurringTextForCB" class="row justify-content-center">
            <div class="col-md-8 col-sm-10 text-center">
              <label class="checkbox-set">{{RecurringTextForCB}}
                <input type="checkbox" v-model="setupRecurringTip"><span class="checkmark"></span>
              </label>
            </div>
            <div class="col-md-8 col-sm-10 text-center">
              <small>{{ recurringHint }}</small>
            </div>
          </div>
        </template>
        <template v-else>
          <h4 class="text-center font-weight-light">Tip received: ${{ tip }}</h4>
        </template>
      </template>
    </template>
  </my-form>
</template>
<script>
import TipField from '@/shared/inputs/text.vue'
import MyForm from './base_form.vue'
import api from '@/clients/api'
import Spinner from '@/shared/non_modal_spinner.vue'
export default {
  props: {
    cents: {
      type: Number,
      default: 0
    },
    percentage: {
      type: Number,
      default: 0
    },
    job: {
      type: Object
    },
    canUpdate: {
      type: Boolean,
      default: false
    },
    saveTip: {
      type: Boolean,
      default: false
    },
    onLightTheme: {
      type: Boolean,
      default: false
    },
    totalCents: {
      type: Number,
      required: true
    },
    reservationId: {
      type: Number
    },
  },
  components: {
    MyForm,
    TipField,
    Spinner
  },
  data() {
    return {
      loading: false,
      pay_url: null,
      rawCustomTip: null,
      rawTipPercentage: null,
      setupRecurringTip: false,
      recurring_tip_percentage: null,
      recurring_tip_amount: null,
    }
  },
  computed: {
    btnVariant() {
      if (this.onLightTheme) {
        return 'light'
      }
      return 'secondary'
    },
    cleaner() {
      if (this.job) {
        return this.job.cleaner
      }
    },
    mainJobId() {
      if (this.job) {
        return this.job.main_job_id
      }
    },
    RecurringTextForCB() {
      let recurringTipOptions
      if (this.recurring_tip_amount) {
        recurringTipOptions = '$' + this.recurring_tip_amount
      }
      if ( (recurringTipOptions == null) && (this.recurring_tip_percentage) ) {
        recurringTipOptions = this.recurring_tip_percentage + '%'
      }
      if (recurringTipOptions) {
        return 'Always tip ' + recurringTipOptions + ' for future cleanings'
      }
    },
    recurringHint() {
      if (this.calculatedTip > 0) {
        if (this.setupRecurringTip) {
          return 'Tip will be saved to the property'
        }
        return 'Tip will be added to the visit only'
      }
      if (this.propertyHasTip) {
        return 'Tip will be removed from the property'
      }
      return 'Tip will be removed from the visit'
    },
    propertyHasTip() {
      return this.percentage > 0 || this.recurring_tip_amount
    },
    tipPercentage: {
      set(v) {
        if (v) {
          this.rawCustomTip = null
          this.recurring_tip_amount = null
          this.rawTipPercentage = v
          this.recurring_tip_percentage = v
        }
      },
      get() {
        return this.rawTipPercentage
      }
    },
    tip() {
      return this.cents / 100
    },
    tipParams() {
      let params = { tip: { tip_amount_cents: null } }
      if (this.mainJobId == null) {
        if (this.reservationId == null) {
          console.error("Reservation ID or job ids are required")
        } else {
          params.reservation_id = this.reservationId
        }
      } else {
        if (this.cleaner.length == 0) {
          console.errors("Missing cleaner name")
        }
        params.cleaning_job_id = this.mainJobId
      }
      if (this.hasTip) {
        params.tip.tip_amount_cents = this.calculatedTip * 100
      }
      if (this.setupRecurringTip) {
        params.tip.recurring_tip_amount = this.recurring_tip_amount
        params.tip.recurring_tip_percentage = this.recurring_tip_percentage
      }
      return params
    },
    calculatedTip() {
      const tipFromPercentage = Math.round(this.totalCents * this.tipPercentage / 10000)
      if (tipFromPercentage > 0) {
        return tipFromPercentage
      }
      return this.customTip
    },
    hasTip() {
      return (this.calculatedTip > 0) || (this.customTip > 0)
    },
    customTip: {
      set(t) {
        if (t) {
          this.rawTipPercentage = null
          this.recurring_tip_percentage = null
          this.recurring_tip_amount = t
          this.rawCustomTip = t
        }
      },
      get() {
        return this.rawCustomTip
      }
    }
  },
  methods: {
    toggleTip(percentage) {
      if (this.tipPercentage == percentage) {
        this.tipPercentage = null
      } else {
        this.tipPercentage = percentage
      }
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.loading = true
          api.addTip(this.tipParams).then((response) => {
            this.loading = false
            this.$emit('update')
            resolve()
          }, (errors) => {
            this.loading = false
            reject(errors)
          })
      })
    },
  },
  watch: {
    cents(v) {
      if (this.saveTip) {
        this.customTip = v / 100
      }
    },
    percentage(v) {
      this.tipPercentage = v
    },
    saveTip(v) {
      this.setupRecurringTip = v
    }
  }
}
</script>
