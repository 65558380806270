<template>
  <div v-if="event" class="row">
    <div v-if="whodunnit" class="col-sm-12">{{ bookingTimeInfo }}</div>
    <div v-if="isStaff && reschedulingInfo" class="col-sm-12">
      <div v-if="reschedulingInfo" class="row">
        <div class="col-sm-12">This visit has been rescheduled {{ rescheduledTotal }} times</div>
        <div class="col-sm-12">Client: {{ rescheduledByClient }} times</div>
        <div class="col-sm-12">Staff: {{ rescheduledByStaff }} times</div>
      </div>
    </div>
  </div>
</template>
<script>
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)
export default {
  props: {
    event: {
      type: Object
    }
  },
  computed: {
    bookingTimeInfo() {
      if (this.whodunnit) {
        return this.whodunnit.action + ' ' + this.whodunnit.actor + ' ' + this.timeAgo
      }
    },
    whodunnit() {
      return this.event.whodunnit
    },
    timeAgo() {
      if (this.event && this.event.whodunnit && this.event.whodunnit.time) {
        const timeAgo = new TimeAgo('en-US')
        return timeAgo.format(new Date(this.event.whodunnit.time))
      }
    },
    reschedulingInfo() {
      if (this.event && this.event.rescheduling_info) {
        return this.event.rescheduling_info
      }
    },
    rescheduledTotal() {
      if (this.reschedulingInfo) {
        return this.reschedulingInfo.total
      }
    },
    rescheduledByClient() {
      if (this.reschedulingInfo) {
        return this.reschedulingInfo.by_client
      }
    },
    rescheduledByStaff() {
      if (this.reschedulingInfo) {
        return this.rescheduledTotal - this.rescheduledByClient
      }
    }
  }
}
</script>
