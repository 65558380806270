<template>
  <span :class="statusColorClass">{{ status }}</span>
</template>
<script>
export default {
  props: {
    job: {
      type: Object,
      required: true
    }
  },
  computed: {
    eta() {
      return this.job.on_my_way_info.eta
    },
    distance() {
      return this.job.on_my_way_info.distance
    },
    hasEta() {
      return !!this.job.on_my_way_info
    },
    status() {
      if (this.job.on_my_way) {
        let fullStatus = "On My Way!"
        if (this.hasEta) {
          fullStatus += " ETA " + this.eta
        }
        if (this.distance) {
           fullStatus += ", " + this.distance
        }
        return fullStatus
      }
      return this.job.job_status
    },
    statusColorClass() {
      return 'text-' + this.job.job_status_color
    }
  },
}
</script>
