<template>
  <div>
    <locker-widget v-if="event" @update:locked="onLockedUpdate" v-bind="lockingParams" record-class="reservation" :record-id="event.id" @reload-needed="$emit('reload-needed')" ref="locker" />
    <template v-if="scheduledEvent">
      <div v-show="!loading" class="box-wrapper mx-2 my-4">
        <schedule-form
          :event="scheduledEvent"
          :mode="scheduleMode"
          @cancel="cancelScheduling"
          @reservation-updated="afterScheduleChange"
          @update:loading="$emit('update:loading', $event)">
          <button slot="below-form" class="btn btn-primary btn-lg">RESCHEDULE</button>
        </schedule-form>
      </div>
    </template>
    <template v-else class="modal-body">
      <event-summary
        v-if="event"
        :event="event"
        :permissions="permissions"
        :loading="loading"
        :readonly="readonly"
        @assign-area="$emit('assign-area', $event)"
        @edit="rescheduleSingleEvent"
        @reschedule="rescheduleAllEvents"
        @confirm="onConfirm(...arguments)"
        @reload-needed="$emit('reload-needed')"
        @reservation-updated="onReservationUpdated"
        @update-lock="onEditModeChange"
        @update:reservation="updateReservation"
        @request-eta="requestEta"
        @send-eta="sendEta"
      />
      <div class="visit-modal-nav mt-0">
        <div>
          <ul class="nav justify-content-center">
            <li class="nav-item"><a class="nav-link active" id="details-tab" data-toggle="tab" href="#details" role="tablist" aria-controls="details-tab" aria-selected="true">Details</a></li>
            <li class="nav-item"><a class="nav-link" @click.passive="onMeetupClick" id="meetup-tab" data-toggle="tab" href="#meetup" role="tablist" aria-controls="meetup-tab" aria-selected="false">Meet Up</a></li>
            <li v-if="isStaff" class="nav-item"><a class="nav-link" id="updates-tab" data-toggle="tab" href="#updates" role="tablist" aria-controls="updates-tab" aria-selected="false">Updates</a></li>
            <li class="nav-item"><a class="nav-link" id="pictures-tab" data-toggle="tab" href="#pictures" role="tablist" aria-controls="pictures-tab" aria-selected="false">Pictures</a></li>
            <li class="nav-item"><a class="nav-link" id="notes-tab" data-toggle="tab" href="#notes" role="tablist" aria-controls="notes-tab" aria-selected="false">Notes</a></li>
            <li v-if="isStaff" class="nav-item"><a class="nav-link" id="reimbursement-tab" data-toggle="tab" href="#reimbursement" role="tablist" aria-controls="reimbursement-tab" aria-selected="false">Reimbursement & Bonus Perk</a></li>
            <li class="nav-item"><a class="nav-link" id="billing-tab" data-toggle="tab" href="#billing" role="tablist" aria-controls="billing-tab" aria-selected="true">Billing</a></li>
            <li v-if="!requestModal && isStaff" class="nav-item"><a class="nav-link" id="tip-tab" data-toggle="tab" href="#tip" role="tablist" aria-controls="tip-tab" aria-selected="false">Give Tip</a></li>
          </ul>
        </div>
      </div>
      <div class="mt-3 px-0 col-12">
        <div class="tab-content">
          <event-details :event="event" @reservation-updated="onReservationUpdated" @close="$emit('close')" @reload-needed="$emit('reload-needed')" @reload-event="onReloadEvent" @update:edit-mode="onEditModeChange" @update:reservation="updateReservation" :loading="loading" :permissions="permissions" />
          <event-meetup :event="event" :loading="loading" :locked="readonly" :show="showMeetup" />
          <div v-if="isStaff" class="px-2 tab-pane fade" id="updates" role="tabpanel" aria-labelledby="updates-tab">
            <event-updates :visit="event" :main-loading="loading" @close="$emit('close')" :locked="readonly" />
          </div>
          <div class="px-2 tab-pane fade" id="pictures" role="tabpanel" aria-labelledby="pictures-tab">
            <job-pictures :visit="event" @close="$emit('close')" :locked="readonly" />
          </div>
          <div class="px-2 tab-pane fade" id="notes" role="tabpanel" aria-labelledby="notes-tab">
            <advanced-notes :event="event" :for-visit="true" @reservation-updated="onReservationUpdated" @reload-event="onReloadEvent" @update:edit-mode="onEditModeChange" @notes:updated="$emit('visit-notes:updated')" :permissions="permissions"></advanced-notes>
          </div>
          <div v-if="isStaff" class="px-2 tab-pane fade" id="reimbursement" role="tabpanel" aria-labelledby="reimbursement-tab">
            <reimbursement :event-id="eventId" @reload-event="onReloadEvent" />
          </div>
          <div class="px-2 tab-pane fade" id="billing" role="tabpanel" aria-labelledby="billing-tab">
            <billing :event-id="eventId"></billing>
          </div>
          <div v-if="!requestModal && isStaff" class="px-2 tab-pane fade" id="tip" role="tabpanel" aria-labelledby="tip-tab">
            <staff-tip
              :event-id="event.id"
              :loading="loading"
              @update:loading="$emit('update:loading', $event)"
            />
          </div>

        </div>
      </div>
    </template>
  </div>
</template>
<script>
import locking_mixin from '@/shared/locking_mixin'
import Base from './base.vue'
import api from '@/clients/api'
export default {
  extends: Base,
  mixins: [locking_mixin],
  beforeDestroy() {
    this.onReservationLockRemove(this.event.id, 'calendar/event modal ' + this.activeUserString)
    this.$refs.locker.unlock()
  },
  created() {
    //this.initLockingCallbacks()
  },
  data() {
    return {
      lockingCallbacksSet: false,
      scheduledEvent: null,
      scheduleMode: 'single',
      showMeetup: false,
      editMode: ''
    }
  },
  computed: {
    activeUserString() {
      if (this.activeUser) {
        return this.activeUser.class + ' ' + this.activeUser.id
      }
    },
    eventId() {
      if (this.event) {
        return this.event.id
      }
    },
    modalTitle() {
      if (this.scheduledEvent) {
        if (this.scheduleMode == 'single') {
          return 'Re-schedule single cleaning'
        }
        return 'Re-scheduling every upcoming cleanings'
      }
    },
  },
  methods: {
    onMeetupClick() {
      this.showMeetup = true
    },
    afterScheduleChange() {
      this.scheduledEvent = null
      this.onReservationUpdated()
    },
    onConfirm(what, event) {
      this.$emit('confirm', what, event)
    },
    onEventLoaded() {
      this.initLockingCallbacks()
    },
    rescheduleAllEvents(event) {
      this.$refs.locker.lock().then(() => {
        this.scheduledEvent = event
        this.scheduleMode = 'all'
      })
    },
    rescheduleSingleEvent(event) {
      this.$refs.locker.lock().then(() => {
        this.scheduledEvent = event
        this.scheduleMode = 'single'
      })
    },
    initLockingCallbacks() {
      const pId = this.event.location_id
      this.onReservationLockChange(this.event.id, 'calendar/event modal ' + this.activeUserString, () => {
        this.$emit('reload-needed')
      })
      this.onLocationLockChange(pId, 'calendar/event modal ' + this.activeUserString, () => {
        this.$emit('reload-needed')
      })
      this.updateLocking({ readonly: this.event.readonly, lockedBy: this.event.locked_by, locked: this.event.locked })
      this.lockingCallbacksSet = true
    },
    cancelScheduling() {
      if (this.$refs.locker) {
        this.$refs.locker.unlock()
      }
      this.scheduledEvent = null
    },
    onReservationUpdated() {
      this.$emit('reservation-updated')
    },
    onReloadEvent() {
      this.$emit('reload-needed')
    },
    onCancelEvent(event, reason, noEmail) {
      this.cancelEvent(event, reason, noEmail)
    },
    onCancelSchedule(event, reason, noEmail) {
      this.cancelSchedule(event, reason, noEmail)
    },
    onCancelWholeSchedule(event, reason, noEmail) {
      this.cancelWholeSchedule(event, reason, noEmail)
    },
    onConfirm(what, event) {
      this.$emit('confirm', what, event)
    },
    resetForm() {
      this.scheduledEvent = null
      this.scheduleMode = 'single'
      this.editMode = ''
    },
    showConfirmationModal(event) {
      this.confirmationModalVisible = true
      //this.confirmSchedule()
    },
    saveNotesChange () {
      return null
    },
    updateReservation(params) {
      this.$emit('update:loading', true)
      api.updateReservation(this.event.id, params).then(response => {
        this.$emit('reload-needed')
      }, errors => {
        this.$flasher.errors(errors.response.data.errors)
        this.$emit('reload-needed')
      })
    },
  },
  watch: {
    event: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.$emit('update:loading', false)
          if (!this.lockingCallbacksSet) {
            this.initLockingCallbacks()
          }
          if (oldValue && (oldValue.readonly != newValue.readonly)) {
            this.updateLocking({ readonly: newValue.readonly, lockedBy: newValue.locked_by, locked: newValue.locked })
          }
        } else {
          this.resetForm()
        }
      }
    },
    scheduledEvent(newValue) {
      this.$emit('update:title', this.modalTitle)
    }
  }
}
</script>
