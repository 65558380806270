<template>
  <div class="box-wrapper px-2 mt-3 col-12">
    <template v-show="!loading" >
      <update-dispute-form v-if="dispute" :dispute="dispute" @reload="getDispute" />
      <p v-else class="lead" >There is no dispute in progress</p>
    </template>
  </div>
</template>
<script>
import { isClient } from '@/utils'
import api from '@/clients/api'
import Spinner from '@/shared/non_modal_spinner.vue'
import UpdateDisputeForm from '@/disputes/update_form.vue'
export default {
  props: {
    eventId: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Spinner,
    UpdateDisputeForm
  },
  data() {
    return {
      dispute: null
    }
  },
  computed: {
  },
  mounted() {
    this.getDispute()
  },
  methods: {
    getDispute() {
      this.$emit('update:loading', true)
      api.getDisputeForVisit(this.eventId).then((response) => {
        this.dispute = response.data
        this.$emit('update:loading', false)
      })
    }
  }
}
</script>
