<template>
  <div class="box-wrapper px-2 mt-3 col-12">
    <spinner :show="loading" />
    <div v-show="!loading" class="accordion" id="reimbursement-and-bonus">
      <div class="card">
        <div class="card-header" id="reimbursement">
          <h2 class="mb-0">
            <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#reimbursement-form" aria-expanded="true" aria-controls="reimbursement-form">Reimbursement (paid by the client)</button>
          </h2>
        </div>

        <div id="reimbursement-form" class="collapse show" aria-labelledby="reimbursement" data-parent="#reimbursement-and-bonus">
          <div class="card-body">
            <reimbursement-form :event-id="eventId" @reload-event="$emit('reload-event')" @update:loading="loading = $event" />
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="bonus">
          <h2 class="mb-0">
            <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#bonus-form" aria-expanded="false" aria-controls="bonus-form">Bonus Perk (paid by WHC)</button>
          </h2>
        </div>
        <div id="bonus-form" class="collapse" aria-labelledby="bonus" data-parent="#reimbursement-and-bonus">
          <div class="card-body">
            <bonus-form :event-id="eventId" @reload-event="$emit('reload-event')" @update:loading="loading = $event" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from '@/shared/non_modal_spinner.vue'
import BonusForm from './bonus_form.vue'
import ReimbursementForm from './reimbursement_form.vue'

export default {
  props: {
    eventId: {
      type: Number
    },
  },
  components: { Spinner, BonusForm, ReimbursementForm },
  data() {
    return {
      loading: false,

      reimbursement: 0,
      reimbursementDescription: null,
      reimbursementPassword: null,
      chargeReimbursementToClient: false,
    }
  },
  computed: {
  },
  methods: {
    save() {

      if (this.reimbursementDescription) {
        params.reimbursement_description = this.reimbursementDescription
        params.reimbursement = this.reimbursement
        params.charge_reimbursement_to_client = this.chargeReimbursementToClient
        if (this.reimbursementPassword) {
          params.password = this.reimbursementPassword
        }
      } else {
        params.reimbursement_description = null
        params.reimbursement = 0
        params.charge_reimbursement_to_client = false
        if (this.reimbursementPassword) {
          params.password = this.reimbursementPassword
        }
      }

    }

  },
  mounted() {

  },
  watch: {
    eventId(newValue) {
      if (newValue) {

      }
    }
  }
}
</script>
