<template>
  <div class="box-wrapper px-2 mt-4 col-12">
    <div class="aditional-notes px-2 my-1">
      <h4 class="timeline-title mt-4">{{title}}</h4>
      <p v-if="inReadOnlyMode">{{body}}</p>
      <div v-if="inReadOnlyMode && attachment1" class="mb-1">
        <img class="img-attach float-left mr-1" src="@images/attach.png" alt="">
        <a :href="attachment1.url" target="blank">{{attachment1.filename}}</a>
      </div>
      <div v-if="inReadOnlyMode && attachment2" class="mb-1">
        <img class="img-attach float-left mr-1" src="@images/attach.png" alt="">
        <a :href="attachment2.url" target="blank">{{attachment2.filename}}</a>
      </div>
      <textarea v-if="inEditMode" rows="3" placeholder="Notes..." class="form-control" v-model="newContent"></textarea>
      <a v-if="inUploadMode" href="#" @click.prevent="cancel">Cancel Upload</a>
      <div v-if="canUpdate" class="edit-attach-set">
        <a v-if="inEditMode" href="#" @click.prevent="cancel"><img width="40" src="@images/close-icon.png" alt="Close icon"></a>
 	      <a v-if="inEditMode" href="#" @click.prevent="ok"><img width="40" src="@images/ok-icon@2x.png" alt="Save"></a>
        <a v-if="inReadOnlyMode && isInactive" href="#" @click.prevent="edit"><img width="40" src="@images/edit-icon02.png" alt="Edit icon02"></a>
        <a v-if="inReadOnlyMode && isInactive" href="#" @click.prevent="upload"><img width="40" src="@images/attach-icon02.png" alt="Attach icon02"></a>
      </div>
      <note-image-upload v-if="inUploadMode" :reservation-id="reservationId" :booking="booking" :note-key="noteKey" file-field="attachment1" :attachment="attachment1" :url="noteUploadUrl" @update="onUpload"></note-image-upload>
      <note-image-upload v-if="inUploadMode" :reservation-id="reservationId" :booking="booking" :note-key="noteKey" file-field="attachment2" :attachment="attachment2" :url="noteUploadUrl" @update="onUpload"></note-image-upload>
      <div class="display-relative text-center">
        <a v-if="inUploadMode" class="btn btn-primary btn-mini" href="#" @click.prevent="cancel"><i class="fal fa-arrow-circle-left">&nbsp;&nbsp;</i>Back</a>
      </div>
    </div>
  </div>
</template>
<script>
import BaseNote from '@/properties/shared/note2.vue'
export default {
  extends: BaseNote,
  computed: {
    canUpdate() {
      if (this.enabled) {
        return !this.cancelled
      }
      return false
    },
    cancelled() {
      return this.note.cancelled
    },
  }

}
</script>
