<template>
  <base-modal dom-id="eventModal" :show="show" :title="title" @close="close">
    <template v-if="loading" slot="content">
      <spinner :show="true" :use-modal="false" message="Loading"></spinner>
    </template>
    <template slot="content">
      <component v-bind:is="contentComponent"
        :event="event"
        :load-event-func="loadEvent"
        :loading="loading"
        :request-modal="requestModal"
        @close="close"
        @reload-needed="onReloadEvent"
        @update:loading="loading = $event"
        @confirm="onConfirm(...arguments)"
        @reservation-updated="onReservationUpdated"
        @update:title="customTitle = $event"
        @do:schedule="doSchedule"
        @change:tab="currentTab = $event"
        @assign-area="$emit('assign-area', $event)" />
      <div class="my-4"></div>
      <div v-show="!loading" class="text-center mx-auto">
        <button type="button" class="btn btn-default btn-primary" @click="close" aria-label="Close">Close</button>
      </div>
      <reference-id :event="event" />
    </template>
  </base-modal>
</template>
<script>
import Vue from 'vue'
import Editable from './visit_modal/editable.vue'
import Readonly from './visit_modal/readonly.vue'
import ReferenceId from './visit_modal/reference_id.vue'
import api from '@/clients/api'
import BaseModal from '@/modals/base_modal.vue'
import Spinner from '@/shared/spinner.vue'
import pubsub_mixin from '@/shared/pubsub_mixin'
import SharedRouter from '../shared_router'

export default {
  props: {
    eventId: {
      type: Number,
      default: null
    },
    locationId: {
      type: Number,
      default: null
    },
    initTab: {
      type: String,
      default: null
    },
    requestModal: {
      type: Boolean,
      default: false
    },
    readonlyProperty: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      required: true
    },
    showMap: {
      type: Boolean,
      default: true
    }
  },
  components: { BaseModal, Editable, Readonly, ReferenceId, SharedRouter, Spinner },
  mixins: [pubsub_mixin],
  computed: {
    contentComponent() {
      if (this.isCleaner) {
        return 'readonly'
      }
      if (this.event) {
        if (this.event.permissions.canUpdate) {
          return 'editable'
        } else {
          return 'readonly'
        }
      }
    },
    title() {
      if (this.customTitle) {
        return this.customTitle
      }
      if (this.event) {
        return 'Visit at ' + this.event.start_date + ' ' + this.event.start_time
      }
      return ''
    },
    changeRequestable() {
      return this.event && this.event.change_requestable
    },
    visitUrl() {
      if (this.event && this.event.location_id) {
        return `/properties/${this.event.location_id}/reservations/${this.eventId}`
      }
    },
    currentTab: {
      set(newTab) {
        if (this.show) {
          $(document).ready(() => {
            $('#' + newTab + '-tab').tab('show')
          })
        }
        this.rawCurrentTab = newTab
      },
      get() {
        if (this.rawCurrentTab) {
          return this.rawCurrentTab
        }
        return this.initTab
      }
    }
  },
  data() {
    return {
      event: null,
      eventIsLoading: false,
      loading: true,
      spinnerMessage: 'Loading',
      customTitle: '',
      requestingChanges: false,
      rawCurrentTab: null,
      subscribed: false,
      wantsRequestChanges: false,
      requestingChanges: false
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.event = null
    },
    doSchedule(updateFunc) {
      this.loading = true
      updateFunc((succeed) => {
        if (succeed) {
          this.onReservationUpdated()
        } else {
          this.loading = false
        }
      })
    },
    loadEvent(eventId, callback) {
      if (this.eventIsLoading) {
        console.log('eventModal loadEvent event is already loading')
        return
      }
      this.eventIsLoading = true
      console.log("eventModal loadEvent", this.event)
      this.loading = true
      api.getReservation(eventId).then(response => {
        this.eventIsLoading = false
        this.event = response.data
        this.updateBrowserUrl()
        if (callback) {
          callback()
        } else {
          $(document).ready(() => {
            $('#' + this.currentTab + '-tab').tab('show')
          })
        }
        this.loading = false
      })
    },
    onReservationUpdated() {
      this.close()
      this.$emit('reservation-updated')
    },
    onConfirm(what, event) {
      this.$emit('confirm', what, event)
    },
    onReloadEvent() {
      console.log("event modal onReloadEvent")
      this.loadEvent(this.eventId)
      this.$emit('reload-needed')
    },
    updateBrowserUrl() {
      if (this.isStaff) {
        SharedRouter.push({path: window.location.pathname })
        SharedRouter.replace({path: this.visitUrl })
      }
    },
    backBrowserUrl() {
      if (this.isStaff) {
        SharedRouter.go(-1)
      }
    }
  },
  watch: {
    show(new_value, old_value) {
      if (new_value === true) {
        this.currentTab = this.initTab ? this.initTab : 'details'
        this.loadEvent(this.eventId)
      }
      if (new_value === false) {
        this.currentTab = 'details'
        this.backBrowserUrl()
      }
    },
    eventId(new_value, old_value) {
      if ((old_value != null) && (new_value == null)) {
        this.close()
      }
      if ((old_value == null) && (new_value != null)) {
        if (!this.subscribed) {
          this.fayeSubscribe('/visits/' + this.eventId + '/job_updated', 'job updated', (channel, msg) => {
            if (this.updatedByOthers(msg.updatedBy)) {
              this.event = null
              this.onReloadEvent()
            }
          })
          this.subscribed = true
        }
        console.log("eventModal eventId changed")
        this.loadEvent(this.eventId)
      }
    },
    readonly(new_value) {
      if (new_value === true) {
        this.cancelScheduling()
      }
    },
    initTab(new_value) {
      this.currentTab = new_value
    }
  }
}
</script>
