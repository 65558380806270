<template>
  <div class="box-wrapper px-2 mt-3 col-12">
    <spinner v-if="loading" :show="true" :use-modal="false" message="Please wait"></spinner>
    <template v-else>
        <report v-if="report" :report-id="report.id" />
        <h5 v-else class="text-warning">{{ error }}</h5>
    </template>
  </div>
</template>
<script>
import adminApi from '@/admins/api'
import Spinner from '@/shared/non_modal_spinner.vue'
import Report from '@/cleaners/inspection_report.vue'

export default {
  props: {
    eventId: {
      type: Number,
      required: true
    }
  },
  components: { Spinner, Report },
  data() {
    return {
        error: null,
        loading: true,
        report: null
    }
  },
  mounted() {
    this.getReport()
  },
  methods: {
    getReport() {
        this.loading = true
        this.report = null
        this.error = null
        adminApi.getInspectionReportForVisit(this.eventId).then(response => {
            this.report = response.data
            this.loading = false
        }, (error) => {
            this.error = error.response.data.error
            this.loading = false
        })
    }
  }
}
</script>
