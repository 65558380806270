<template>
  <my-form :start-in-edit-mode="!alreadyRated" success-message="We have received your feedback. Thank you!" :readonly="loading || alreadyRated">
    <template v-slot:default="{ invalid, valid, formIsReadonly }">
      <spinner v-if="loading" />
      <template v-else>
        <h2 class="form-head mb-2 text-center">{{title}}</h2>
        <div class="row justify-content-center my-1">
          <div class="col-auto">
            <star-rating v-model="ourRating" :show-rating="false" :star-size="30" inactive-color="#CCCCCC" active-color="#44A0ED" :glow="1" :rounded-corners="true" :padding="20" :read-only="formIsReadonly" :increment="0.5"></star-rating>
          </div>
        </div>
        <div class="row mb-3">
        </div>
        <div class="row">
          <div class="col-sm text-center">
            <comment-field label="Comment" v-model="ourFeedback" name="feedback" placeholder="Enter your feedback" :readonly="formIsReadonly"></comment-field>
          </div>
        </div>
        <template v-if="isClient">
          <template v-if="alreadyTipped">
            <h4 class="text-center font-weight-light">Tip received: ${{rawCustomTip}}</h4>
          </template>
          <template v-if="!alreadyRated && !alreadyTipped">
            <h4 class="text-center font-weight-light">You can also add a tip for {{cleaner}}</h4>
            <div class="text-center my-3">
              <div class="tips-button-group">
                <b-button v-for="percentage in [10,15,20]" @click="tipPercentage = percentage" :class="tipPercentage === percentage ? 'active': ''" class="btn btn-round" :key="percentage" :variant="btnVariant">{{percentage}} <i class="fal fa-percent"></i> </b-button>
                <h6 v-if="tipPercentage">Amount: ${{calculatedTip}}</h6>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6 col-sm-8">
                <tip-field label="Or enter a custom amount below" v-model="customTip" name="tip"></tip-field>
              </div>
            </div>
            <div v-if="RecurringTextForCB" class="row justify-content-center">
              <div class="col-md-8 col-sm-10">
                <label class="checkbox-set">{{RecurringTextForCB}}
                  <input type="checkbox" v-model="setupRecurringTip"><span class="checkmark"></span>
                </label>
              </div>
            </div>
          </template>
        </template>
        <div v-else class="row justify-content-center text-center bg-light p-4 m-2">
          <div class="col-sm-6">
            <a href="#" @click="startDispute" class="btn btn-orange">Start Dispute</a>
          </div>
           <div v-if="shortStartDisputeUrl" class="col-sm-12">
            <magic-url :url="shortStartDisputeUrl" title="Start Dispute"></magic-url>
          </div>
          <div class="col-sm-6">
            <button @click="sendStartDisputeLink" class="btn btn-warning">Send Link to Client</button>
          </div>
        </div>
      </template>
    </template>
    <template v-slot:readonly-buttons></template>
    <template v-slot:edit-buttons="{ invalid, validated }">
      <b-button :disabled="invalid && validated" type="submit" variant="primary main-btn mt-4">Complete</b-button>
      <div v-if="isClient" class="row">
        <div class="col-12 mt-2 py-0">
          <p class="blue-text font-weight-bold font-16 text-center my-0 py-0">
            <a class="blue-text font-weight-light my-0 py-0 cursor-pointer" data-toggle="collapse" data-target="#more">
              <i class="fal fa-plus"></i> &nbsp;More Options
            </a>
          </p>
        </div>
        <div class="collapse mx-auto" id="more">
          <b-button variant="warning main-btn mt-4" data-toggle="collapse" data-target="#help">Help</b-button>
        </div>
      </div>
      <div v-if="isClient" class="row">
        <div class="collapse mx-auto" id="help">
          <b-button @click="showIntercom" variant="primary btn-sm mt-4">Chat with Support</b-button>
          <a @click="startDispute" href="#" class="btn btn-danger btn-sm mt-4">Dispute Cleaning</a>
        </div>
      </div>
    </template>
  </my-form>
</template>
<script>
import CommentField from '@/shared/inputs/text_area.vue'
import TipField from '@/shared/inputs/text.vue'
import StarRating from 'vue-star-rating'
import MyForm from './base_form.vue'
import api from '@/clients/api'
import adminApi from '@/admins/api'
import Spinner from '@/shared/non_modal_spinner.vue'
import MagicUrl from '@/shared/magic_url.vue'
import pubsub_mixin from '@/shared/pubsub_mixin'
export default {
  mixins: [pubsub_mixin],
  props: {
    rating: {
      type: Number,
      required: true
    },
    feedback: {
      type: String,
      required: true
    },
    tipCents: {
      type: Number,
      default: 0
    },
    cleaner: {
      type: String,
      required: true
    },
    jobIds: {
      type: Array,
      required: true
    },
    mainJobId: {
      type: Number,
      required: true
    },
    onLightTheme: {
      type: Boolean,
      default: false
    },
    shortStartDisputeUrl: {
      type: String,
      required: true
    },
    totalCents: {
      type: Number
    }
  },
  components: {
    MagicUrl,
    MyForm,
    CommentField,
    StarRating,
    TipField,
    Spinner
  },
  data() {
    return {
      ourFeedback: this.feedback,
      loading: false,
      ourRating: this.rating,
      pay_url: null,
      rawCustomTip: this.tipCents / 100,
      rawTipPercentage: null,
      setupRecurringTip: false,
      recurring_tip_percentage: null,
      recurring_tip_amount: null,
    }
  },
  computed: {
    alreadyRated() {
      return this.rating > 0
    },
    alreadyTipped() {
      return this.tipCents > 0
    },
    btnVariant() {
      if (this.onLightTheme) {
        return 'light'
      }
      return 'secondary'
    },
    RecurringTextForCB() {
      var recurringTipOptions;
      if (this.recurring_tip_percentage && (this.recurring_tip_percentage > 0)) {
        recurringTipOptions = this.recurring_tip_percentage + '%'
      }
      if (this.recurring_tip_amount && (this.recurring_tip_amount > 0)) {
        recurringTipOptions = '$' + this.recurring_tip_amount
      }
      if (recurringTipOptions) {
        return 'Always tip ' + recurringTipOptions + ' for future cleanings'
      }
    },
    title() {
      if (this.alreadyRated) {
        return 'Rating received'
      }
      if (this.isClient) {
        return 'Please rate the cleaning you have received'
      }
      return 'Rate the cleaning'
    },
    tipPercentage: {
      set(v) {
        this.rawCustomTip = null
        this.recurring_tip_amount = null
        this.rawTipPercentage = v
        this.recurring_tip_percentage = v
      },
      get() {
        return this.rawTipPercentage
      }
    },
    calculatedTip() {
      if (this.totalCents) {
        const tipFromPercentage = Math.round(this.totalCents * this.tipPercentage / 10000)
        if (tipFromPercentage > 0) {
          return tipFromPercentage
        }
      } else {
        console.error("totalCents is null")
      }
      return this.customTip
    },
    hasTip() {
      return (this.calculatedTip > 0) || (this.customTip > 0)
    },
    customTip: {
      set(t) {
        if (t > 0) {
          this.rawTipPercentage = null
          this.recurring_tip_percentage = null
          this.rawCustomTip = t
          this.recurring_tip_amount = t
        }
      },
      get() {
        return this.rawCustomTip
      }
    }
  },
  mounted() {
    this.subscribeToUpdates()
  },
  methods: {
    sendStartDisputeLink() {
      this.loading = true
      adminApi.sendStartDisputeLink(this.mainJobId).then(() => {
        this.$flasher.success('Link sent')
        this.loading = false
      }, errors => {
        this.$flasher.errors(errors.response.data.errors)
        this.loading = false
      })
    },
    showIntercom() {
      this.$intercom.show()
    },
    startDispute() {
      // router only availabe for clients currently
      if (this.$router) {
        this.$router.push({ path: `/start_dispute/${this.jobIds[0]}` })
      } else {
        window.location = `/disputes/new?cleaning_job_id=${this.jobIds[0]}`
      }
    },
    subscribeToUpdates() {
      if (!this.subscribed && this.mainJobId) {
        this.fayeSubscribe('/jobs/' + this.mainJobId + '/feedback', 'updated', (channel, msg) => {
          if (this.updatedByOthers(msg.updatedBy)) {
            this.$emit('update')
          }
        })
        this.subscribed = true
      }
    },
    submit() {
      const params = {
        ids: this.jobIds,
        cleaning_job: {
          rating: this.ourRating,
          feedback: this.ourFeedback,
        }
      }
      var tipParams = { tip: { tip_amount_cents: null }, cleaning_job_id: this.mainJobId }

      if (this.hasTip) {
        tipParams.tip.tip_amount_cents = this.calculatedTip * 100
      }
      if (this.setupRecurringTip) {
        tipParams.tip.recurring_tip_amount = this.recurring_tip_amount
        tipParams.tip.recurring_tip_percentage = this.recurring_tip_percentage
      }
      return new Promise((resolve, reject) => {
        if (this.ourRating < 1) {
          reject({
            errors: ['Please rate the service']
          })
        } else {
          this.loading = true
          api.rateCleaningJobs(params).then(() => {
            if ( (this.hasTip > 0) && !this.alreadyTipped) {
              api.addTip(tipParams).then((response) => {
                this.loading = false
                this.$emit('update')
                resolve()
              })
            } else {
              this.loading = false
              this.$emit('update')
              resolve()
            }
          })
        }
      })
    },
  },
  watch: {
    rating() {
      if (this.rating) {
        this.ourRating = this.rating
        this.ourFeedback = this.feedback
      }
    }
  }
}
</script>
