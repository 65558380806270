<template>
  <div v-if="visit" class="updates">
    <spinner :show="loading" :use-modal="false" message="Loading"></spinner>
    <template v-if="!loading">
      <event-for-visit-updates-tab v-for="(updateEvent, idx) in updates" :key="idx" :event="updateEvent" :visit="visit" :dom-id="'br-' + idx" :last="(idx+1) == updates.length" @reload="loadUpdates" />
    </template>
  </div>
</template>
<script>
import sharedApi from '@/shared/api'

import Spinner from '@/shared/spinner.vue'
import EventForVisitUpdatesTab from '@/shared/events/for_visit_updates_tab.vue'
import pubsub_mixin from '@/shared/pubsub_mixin'
import {
  isAdmin,
  isAgent,
  isClient
} from '@/utils'

export default {
  data() {
    return {
      ourLoading: false,
      updates: []
    }
  },
  props: {
    visit: {
      type: Object
    },
    mainLoading: {
      type: Boolean
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Spinner,
    EventForVisitUpdatesTab
  },
  mixins: [pubsub_mixin],
  computed: {
    loading() {
      return this.ourLoading || this.mainLoading
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    loadUpdates() {
      this.ourLoading = true
      sharedApi.getVisitUpdates(this.visit.id).then(response => {
        this.updates = response.data
        this.subscribeToUpdates()
        this.ourLoading = false
      }, error => {
        this.ourLoading = false
        this.$flasher.error(error.response.data.errors)
      })
    },
    subscribeToUpdates() {
      if (!this.subscribed && this.visit.id) {
        this.fayeSubscribe('/visits/' + this.visit.id + '/updates', 'event updates', (channel, msg) => {
          console.log("event update", channel, msg)
          this.loadUpdates()
        })
      }
    }
  },
  mounted() {
    if (this.visit) {
      this.loadUpdates()
    }
  },
  watch: {
    readonly(newValue) {
      if (newValue === true) {
        //        this.cancelPropertyEdit()
      }
    },
    visit(newValue) {
      if (newValue) {
        this.subscribeToUpdates()
        this.loadUpdates()
      }
    }
  }
}
</script>
