<template>
  <div class="box-wrapper px-2 mt-3 col-12">
    <div v-if="loading" class="d-flex align-items-center justify-content-around"><spinner /></div>
    <template v-if="!loading">
      <template v-if="payments.length > 0">
        <payment-row v-for="(payment, idx) in payments" :payment="payment" :key="idx" @reload="loadRecentPayments" :show-visit-link="false"></payment-row>
      </template>
      <template v-else>No records found for this visit</template>
    </template>
  </div>
</template>
<script>
import Spinner from '@/shared/inline_spinner.vue'
import api from '@/admins/api'
import PaymentRow from '@/shared/payment_row.vue'
export default {
  props: {
    eventId: {
      type: Number
    },
  },
  components: { Spinner, PaymentRow },
  data() {
    return {
      loading: false,
      payments: [],
    }
  },
  methods: {
    loadRecentPayments() {
      this.loading = true
      api.getRecentPaymentsOfVisit(this.eventId).then((response) => {
        this.payments = response.data.payments
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.eventId) {
      this.loadRecentPayments()
    }
  },
  watch: {
    eventId(newValue) {
      if (newValue) {
        this.loadRecentPayments()
      }
    }
  }
}
</script>
