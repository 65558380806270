<template>
  <div class="card border-light">
    <a target="_blank" class="js-link" :href="url"><img class="card-img-top thumbnail" :src="thumb.url"></a>
    <div v-if="isStaff" class="card-body">
      <p class="card-text">{{status}}</p>
      <p class="card-text">
        <a v-if="pending" @click="approve" href="#" class="text-success">Approve</a>
        <a @click="reject" href="#" class="text-danger">Reject</a>
      </p>
    </div>
  </div>
</template>
<script>
import api from '@/clients/api'

export default {
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  computed: {
    domId() {
      return "reason_" + this.image.id
    },
    pending() {
      return this.image.approved == false
    },
    status() {
      if (this.image.approved) {
        return 'Approved'
      } else {
        return 'Pending'
      }
    },
    url() {
      return this.image.url
    },
    thumb() {
      return this.image.thumb
    }
  },
  methods: {
    approve() {
      api.approveJobImage(this.image.id).then(response => {
        this.$emit('reload')
      })
    },
    reject(form) {
      var do_not_notify = false
      this.$dialog.prompt({}, {
        promptHelp: 'Why do you want to reject this image?',
        html: true
      })
      .then(dialog => {
        if (dialog.data && dialog.data.length > 5) {
          api.rejectJobImage(this.image.id, dialog.data).then(response => {
            this.$emit('reload')
          })
        } else {
          this.$dialog.alert('You need to enter the reason (at least 5 characters) to reject an image')
        }
      })
    }
  }
}
</script>
