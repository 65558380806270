<template>
  <div class="row button-section mb-4 justify-content-center text-center">
    <spinner :show="loading" :use-modal="false" message="Loading"></spinner>
    <div v-if="!loading" class="col-12">
      <div class="row">
        <div class="col-12">
          <button v-if="unassignable || cancellationApproveable" class="btn btn-mini btn-red" @click="unassignWithConfirm">Unassign</button>
          <button v-if="resetable || cancellationApproveable" class="btn btn-mini btn-warning" @click="resetWithConfirmation">Reset</button>
          <contact-client-button v-if="contactable" :job-id="job.id" />
        </div>
      </div>
      <div v-if="completedAgo" class="row">
        <div class="col-12">
          <small >{{completedAgo}}</small>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <h6 v-if="showDescription" class="text-info">The buttons below just update the job without sending any notification.</h6>
          <h6 v-if="cancellationReason" class="text-danger">{{cancellationReason}}</h6>
          <cancellation-details v-if="cancellationApproveable" :job="job" @approve="approveCancellation" :loading="loading" />
          <button v-if="completable" class="btn btn-primary" :disabled="loading" @click="completeWithConfirmation">Mark as Complete</button>
          <button v-if="uncompletable" class="btn btn-danger" :disabled="loading" @click="uncompleteWithConfirmation">Uncomplete</button>
          <template v-if="startable">
            <button class="btn btn-mini btn-primary" :disabled="loading" @click="start">Start Timer</button>
          </template>
          <template v-if="restartable">
            <button class="btn btn-mini btn-danger" :disabled="loading" @click="restart">Restart Timer</button>
          </template>
          <template v-if="assigned && canUpdate || cancellationApproveable">
            <div class="btn-group" role="group">
              <button v-if="assigned && canUpdate" class="btn btn-default btn-success" :disabled="loading" @click="accept">Accept Job</button>
              <button class="btn btn-default btn-danger" :disabled="loading" @click="reject">Reject Job</button>
              <button class="btn btn-default btn-dark" :disabled="loading" @click="expire">Expire Job</button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)
import Vue from 'vue'
import api from '@/admins/api'
import Spinner from '@/shared/spinner.vue'
import Flasher from '@/flasher'
Vue.use(Flasher)
import unassignment_confirmation_mixin from '@/shared/confirmation_for_unassignment_mixin'
import ContactClientButton from '@/shared/contact_client_button.vue'
import CancellationDetails from '@/shared/cancellation_details.vue'
export default {
  props: [ 'jobId', 'requireConfirmUnassign', 'readonly', 'canUpdate' ],
  components: { Spinner, ContactClientButton, CancellationDetails  },
  mixins: [ unassignment_confirmation_mixin ],
  computed: {
    cancelled() {
      return this.job.cancelled
    },
    cancellationApproveable() {
      return this.job.cleaner_cancelled
    },
    cancellationReason() {
      return this.job.cancellation_reason
    },
    contactable() {
      return this.job.contactable
    },
    contactedAgo() {
      if (this.completed) {
        return
      }
      if (this.job.contacted_at) {
        const timeAgo = new TimeAgo('en-US')
        return 'Client last contacted ' + timeAgo.format(new Date(this.job.contacted_at))
      }
    },
    completedAgo() {
      if (this.completed && this.job.completed_at) {
        const timeAgo = new TimeAgo('en-US')
        return 'Completed ' + timeAgo.format(new Date(this.job.completed_at))
      }
    },
    completable() {
      return !this.readonly && this.job.completable && this.canUpdate
    },
    completed() {
      return this.job.completed
    },
    assigned() {
      return this.job.assigned
    },
    resetable() {
      return !this.readonly && this.job.resetable && this.canUpdate
    },
    startable() {
      return !this.readonly && this.job.startable && this.canUpdate
    },
    restartable() {
      return !this.readonly && this.job.completable && this.canUpdate
    },
    unassignable() {
      return !this.readonly && this.job.unassignable && this.canUpdate
    },
    uncompletable() {
      return !this.readonly && this.job.uncompletable && this.canUpdate
    },
    showDescription() {
      return this.completable || this.startable || this.assigned
    }
  },
  data() {
    return {
      job: {},
      loading: false
    }
  },
  mounted() {
    this.loadJob()
  },
  methods: {
    approveCancellation() {
      this.loading = true
      api.cancelCleaningJob(this.jobId).then(() => {
        this.$emit('update')
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
        this.$emit('update')
      })
    },
    callOff() {
      this.loading = true
      api.cancelCleaningJob(this.jobId, { call_off: 1 }).then(() => {
        this.$emit('update')
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
        this.$emit('update')
      })
    },
    loadJob() {
      this.loading = true
      api.getCleaningJob(this.jobId).then((response) => {
        this.job = response.data
        this.loading = false
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
      })
    },
    contactClient() {
      this.loading = true
      api.contactCleaningJobClient(this.jobId).then(() => {
        this.$emit('update')
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
        this.$emit('update')
      })
    },
    complete() {
      this.loading = true
      api.completeCleaningJob(this.jobId).then(() => {
        this.$emit('update')
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
        this.$emit('update')
      })
    },
    completeWithConfirmation() {
      this.$dialog.confirm('Are you sure you want to complete this job?', {
          okText: 'Yes',
          cancelText: 'No'
        })
        .then((dialog) => {
          this.complete()
        })
    },
    uncomplete() {
      this.loading = true
      api.uncompleteCleaningJob(this.jobId).then(() => {
        this.$emit('update')
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
        this.$emit('update')
      })
    },
    uncompleteWithConfirmation() {
      this.$dialog.confirm('Are you sure you want to uncomplete this job?', {
          okText: 'Yes',
          cancelText: 'No'
        })
        .then((dialog) => {
          this.uncomplete()
        })
    },
    start() {
      this.loading = true
      api.startCleaningJob(this.jobId).then(() => {
        this.$emit('update')
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
        this.$emit('update')
      })
    },
    restart() {
      this.loading = true
      api.restartCleaningJob(this.jobId).then(() => {
        this.$emit('update')
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
        this.$emit('update')
      })
    },
    accept() {
      this.loading = true
      api.acceptCleaningJob(this.jobId)
        .then(() => {
          this.$emit('update')
        },
        (error) => {
          this.loading = false
          this.$flasher.errors(error.response.data.errors)
          this.$emit('update')
        })
    },
    reject() {
      this.loading = true
      api.rejectCleaningJob(this.jobId).then(() => {
        this.$emit('update')
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
        this.$emit('update')
      })
    },
    reset() {
      this.loading = true
      api.resetCleaningJob(this.jobId).then(() => {
        this.$emit('update')
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
        this.$emit('update')
      })
    },
    resetWithConfirmation() {
      this.$dialog.confirm('Are you sure you want to reset this job?', {
          okText: 'Yes',
          cancelText: 'No'
        })
        .then((dialog) => {
          this.reset()
        })
    },
    expire() {
      this.loading = true
      api.expireCleaningJob(this.jobId).then(() => {
        this.$emit('update')
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
        this.$emit('update')
      })
    },
    unassign(other_args) {
      console.log("unassign", other_args)
      this.loading = true
      api.unassignJob(this.jobId, { context: 'visit_modal', ...other_args }).then(response => {
        this.$emit('update')
      }, response => {
        this.loading = false
        this.$flasher.errors(response.data.errors)
        this.$emit('update')
      })
    },
    unassignForConfirmation(other_args) {
      console.log("unassignForConfirmation", other_args)
      this.unassign(other_args)
    },
    unassignWithConfirm() {
      if (this.requireConfirmUnassign) {
        this.confirmUnassignment(this.unassignForConfirmation)
      } else {
        this.unassign()
      }
    },
  }
}
</script>
