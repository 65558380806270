<template>
  <div class="box-wrapper px-2 mt-3 col-12">
    <template v-if="!loading && job" >
      <div v-if="showRemindButton" class="row justify-content-center">
        <div class="col-sm-11 alert alert-warning text-center">Job is not rated. Feedback and tip can only be given after the job is rated.</div>
        <button @click="remindClient" class="col-sm-3 btn btn-warning text-center">Remind Client</button>
      </div>
      <rate-form v-if="job.rating"
        :cleaner="cleaner"
        :job-ids="job.ids"
        :main-job-id="mainJobId"
        :total-cents="totalCents"
        :on-light-theme="true"
        :rating="job.rating"
        :feedback="job.feedback"
        :short-start-dispute-url="job.short_start_dispute_url"
        :tip-cents="job.tip_cents"
        @update="getJob"
      ></rate-form>
    </template>
    <template v-else>
      <spinner />
    </template>
  </div>
</template>
<script>
import api from '@/clients/api'
import adminApi from '@/admins/api'
import RateForm from '@/shared/rate_form.vue'
import Spinner from '@/shared/non_modal_spinner.vue'
export default {
  props: {
    eventId: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  components: {
    RateForm,
    Spinner
  },
  data() {
    return {
      job: null
    }
  },
  computed: {
    cleaner() {
      return this.job.cleaner
    },
    mainJobId() {
      return this.job.main_job_id
    },
    showRemindButton() {
      if (this.isClient) {
        return false
      }
      return this.job.rating == 0
    },
    totalCents() {
      return this.job.total_cents
    }
  },
  mounted() {
    this.getJob()
  },
  methods: {
    getJob() {
      api.getJobForVisit(this.eventId).then((response) => {
        this.job = response.data.job
      })
    },
    remindClient() {
      this.$emit('update:loading', true)
      adminApi.sendFeedbackReminder(this.mainJobId).then(() => {
        this.$flasher.success('Reminder email sent')
        this.$emit('update:loading', false)
      }, errors => {
        this.$flasher.errors(errors.response.data.errors)
        this.$emit('update:loading', false)
      })
    }
  }
}
</script>
