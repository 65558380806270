<template>
  <my-form :readonly="!canUpdate" ref="form" :report-success="false" :force-edit-mode="canUpdate" :start-in-edit-mode="canUpdate">
    <template v-slot:default="{ invalid, valid, errors, formIsReadonly }">
      <p class="text-info"><strong>Bonus Perk:</strong> this is an amount of money added to payout on a per case basis, especially as a reward for good performance or incentive.</p>
      <p class="text-info">Example: a cleaner receives a bonus perk for accepting additional houses far from their current location to motivate the cleaner to arrive at the client's visit.</p>
      <div v-if="canUpdate" class="row">
        <template v-if="requirePassword">
          <div class="col-11 mb-4">
            <my-text-input
              label="Password"
              v-model="password"
              name="password"
              description="You can get the password from your service area admin"
              rules="required"
            ></my-text-input>
          </div>
        </template>
        <div class="col-7 full-select">
          <div class="blue-line-select">
            <dropdown v-model="description" :show-label="false" :options="options" />
          </div>
        </div>
        <div class="col-5 blue-text">
          <amount-field v-model="amount" placeholder="Amount" :hide-label="true" :format-as-currency="true" rules="required|min:1|min_value:0" />
        </div>
        <div v-if="description == 'other'" class="col-12">
          <my-text-input
            v-model="descriptionText"
            name="description"
            :hide-label="true"
            placeholder="Description"
            rules="required|min:5"
            :readonly="!canUpdate"
          />
        </div>
        <p class="col-12 text-info"><strong>The whole amount goes to cleaner(s), paid by WHC</strong></p>
      </div>
      <div v-else class="text-danger"><strong>Already has an invoiced bonus perk</strong></div>
    </template>
    <template v-slot:edit-buttons="{ validated, invalid }">
      <b-button class="btn btn-default btn-primary" :disabled="invalid && validated" variant="primary" type="submit">Submit</b-button>
      <b-button class="btn btn-default" variant="danger" @click="remove">Remove</b-button>
    </template>
  </my-form>
</template>
<script>
import Spinner from '@/shared/spinner.vue'
import MyForm from '@/shared/base_form.vue'
import MyTextInput from '@/shared/inputs/base.vue'
import AmountField from '@/shared/fields/amount.vue'
import Dropdown from '@/shared/inputs/base_select.vue'
import api from '@/admins/api'

export default {
  props: {
    eventId: {
      type: Number
    },
  },
  components: { Spinner, MyForm, MyTextInput, AmountField, Dropdown },
  data() {
    return {
      amountCents: 0,
      description: null,
      password: null,
      requirePassword: false,
      canUpdate: false,
      options: [
        { label: 'Gas', value: 'gas' },
        { label: 'Incentive', value: 'incentive' },
        { label: 'Other', value: 'other' },
      ],
      descriptionText: null,
      error: null
    }
  },
  computed: {
    amount: {
      get() {
        return this.amountCents * 0.01
      },
      set(v) {
        this.amountCents = Math.round(v * 100)
      }
    },
    params() {
      let params = { amount_cents: this.amountCents, description: this.description }
      if (this.requirePassword) {
        params.password = this.password
      }
      if (this.description == 'other') {
        params.description = this.descriptionText
      }
      return params
    }
  },
  methods: {
    getBonus() {
      this.$emit('update:loading', true)
      api.getVisitBonus(this.eventId).then(response => {
        this.amountCents = response.data.amount_cents
        this.description = response.data.description
        this.descriptionText = response.data.description
        if ( (this.description != 'gas') && (this.description != 'incentive')) {
          this.description = 'other'
        }
        this.requirePassword = response.data.require_password
        this.canUpdate = response.data.can_update
        this.$emit('update:loading', false)
      }, errors => {
        this.$emit('update:loading', false)
        this.$flasher.errors(errors.response.data.errors)
      })
    },
    remove() {
      this.$emit('update:loading', true)
      api.removeVisitBonus(this.eventId).then(() => {
        this.$emit('update:loading', false)
        this.$emit('reload-event')
        resolve()
      })
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.$emit('update:loading', true)
        api.updateVisitBonus(this.eventId, this.params).then(() => {
          this.$emit('update:loading', false)
          this.$emit('reload-event')
          resolve()
        }, errors => {
          this.$emit('update:loading', false)
          reject(errors)
        }).catch(() => {
          this.$emit('update:loading', false)
          reject(false)
        })
      })
    }
  },
  mounted() {
    this.getBonus()
  }
}
</script>
