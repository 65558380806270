<template>
  <div v-if="event" class="status" :class="statusClass"></div>
</template>
<script>
import { isAdmin, isAgent, isClient, isCleaner } from '../utils'
export default {
  props: [ 'event' ],
  computed: {
    //// deep -> Green, basic -> Blue, confirmation -> Yellow -->
    statusClass() {
      if (this.event) {
        return this.event.status_color + '-bg'
      }
    },
  }
}
</script>
