<template>
  <div class="row mt-2">
    <div class="col-12">
      <div class="timeline-info-container mt-3 mb-4 px-4 d-flex">
        <visit-status-icon :event="event" />
        <div class="container">
          <div v-if="event" class="row">
            <div class="col-12">
              <pending-warning :event="event"></pending-warning>
              <h4 v-if="cancelled">{{cancelledTitle}}</h4>
              <div class="row justify-content-center">
                <template v-if="showWidgets">
                  <lock-visit-widget v-if="event && canLockVisit" @reservation-updated="$emit('reload-needed')" :event-id="event.id" :manual="event.manual"></lock-visit-widget>
                  <test-visit-widget
                    v-if="event && isAdmin && false"
                    @update:reservation="$emit('update:reservation', $event)"
                    :event-id="event.id"
                    :test="event.test"
                  ></test-visit-widget>
                  <gcal-status-widget v-if="false && event && canStaffUpdateAnytime" :event-id="event.id" :color-id="event.gcal_color_id"></gcal-status-widget>
                </template>
                <div class="col-12" v-if="showAskSupportMessage">
                  <div class="alert alert-warning text-center">Contact staff to change anything for today's visit at <strong class="line-break">{{companyPhone}}</strong></div>
                </div>
              </div>
            </div>
          </div>
          <visit-client-info :event="event" :can-request-eta="canRequestETA" :show-request-eta="!cancelling" />
          <visit-booking-info v-if="!cancelling" :event="event" />
          <visit-assignment
            v-if="showTeamInfo"
            :reservation-id="event.id"
            :location-id="event.location_id"
            :test-visit="event.test"
            :require-confirm-unassign="requireConfirmUnassign"
            :permissions="permissions"
            :readonly="readonly"
            :client-statuses="clientStatuses"
            @assigning="onAssigning"
            @cancel-assigning="onCancelAssigning"
            @update-lock="$emit('update-lock', $event)"
            @reload="$emit('reload-needed')"/>
          <template v-if="showWidgets && !this.cancelling">
            <div v-if="canUpdate" class="row d-flex justify-content-center">
              <div class="col-md-6 my-2 mx-auto">
                <div v-if="canWelcome" class="full-select">
                  <div class="blue-line-select">
                    <dropdown v-model="action" blank-label="Send Emails" :show-label="false" :options="availableAdminActions"></dropdown>
                  </div>
                </div>
              </div>
              <div class="col-md-6 my-2 mx-auto">
                <div v-if="action == null  && isStaff" class="full-select">
                  <div class="blue-line-select reschedule">
                    <dropdown v-model="action" blank-label="Reschedule" :show-label="false" :options="availableRescheduleActions"></dropdown>
                  </div>
                </div>
              </div>
              <button v-if="canComplete" type="button" class="btn btn-default btn-green" @click="markComplete">Mark Complete</button>
              <button v-if="canConfirm" type="button" class="btn btn-default btn-primary" @click="confirm">Confirm</button>
              <div v-if="action == null && isClient" class="col-12">
                <h2 class="sub-head text-center mb-1">Reschedule</h2>
                <div class="text-center mx-auto">
                  <button @click="edit" type="button" aria-label="Close" class="btn btn-default btn-primary">Single Visit</button>
                  <button v-if="isRecurring" @click="update" type="button" aria-label="Close" class="btn btn-default btn-blue-line">This and future visits</button>
                </div>
              </div>
            </div>
            <div class="col-12">
              <p class="my-0 text-center">
                <a :href="'/properties/'+locationId" target="_blank"><i class="fal fa-home">&nbsp;</i> View property</a>
              </p>
            </div>
          </template>

          <div v-if="showCancel" class="row justify-content-center align-items-center">
            <div class="col-sm-4 text-center">
              <b-button variant="link" class="text-danger" @click="openCancelation('client')">Cancel for Client</b-button>
            </div>
            <div v-if="hasJob" class="col-sm-4">
              <b-button variant="link" class="text-danger" @click="openCancelation('cleaner')">Cancel for Cleaner</b-button>
            </div>
          </div>

          <admin-private-notes :property-id="locationId" />
        </div>
      </div>
    </div>
    <admin-cancellation-widget
      v-if="cancelling"
      :event="event"
      :user-type="userType"
      @update:action="action=$event"
      @reservation-updated="$emit('reservation-updated')"
    />
  </div>
</template>
<script>
import PendingWarning from '@/shared/pending_warning.vue'
import LockVisitWidget from './lock_visit_widget.vue'
import TestVisitWidget from './test_visit_widget.vue'
import AdminInput from '@/shared/inputs/text.vue'
import AdminCancellationWidget from './cancellation_widget.vue'
import AdminPrivateNotes from './admin_private_notes.vue'
import GcalStatusWidget from './gcal_status_widget.vue'
import VuejsDialog from 'vuejs-dialog'
import Dropdown from '@/shared/inputs/base_select.vue'
import VisitBookingInfo from '@/shared/visit_booking_info.vue'
import VisitClientInfo from '@/shared/visit_client_info.vue'
import VisitAssignment from '@/shared/visit_assignment.vue'
import VisitStatusIcon from '@/shared/visit_status_icon.vue'
import permissions_mixin from '@/shared/permissions_mixin'
import company_phone_mixin from '@/shared/company_phone_mixin'
export default {
  props: {
    event: {
      type: Object
    },
    permissions: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      required: true
    }
  },
  created() {
    this.$on('updateValidations', function(e) {
      this.checkForm()
    })
  },
  components: { AdminInput, LockVisitWidget, TestVisitWidget, PendingWarning, AdminCancellationWidget, AdminPrivateNotes, GcalStatusWidget, Dropdown, VisitAssignment, VisitClientInfo, VisitStatusIcon, VisitBookingInfo },
  mixins: [ company_phone_mixin, permissions_mixin ],
  computed: {
    address() {
      return this.event.address
    },
    availableRescheduleActions() {
      var options = []
      if (this.editable) {
        options.push({ label: 'Single Visit', value: 'edit' })
      }
      if (this.updateable) {
        options.push({ label: 'This and Future Visits', value: 'update' })
      }
      return options
    },
    availableAdminActions() {
      var options = []
      if (this.resendable) {
        options.push({ label: 'Resend Client Reminder', value: 'resendable' })
      }
      if (this.canWelcome) {
        options.push({ label: 'Welcome Email', value: 'sendWelcome' })
      }
      return options
    },
    cancelling() {
      return this.action == 'cancel'
    },
    cancelled() {
      return this.event && this.event.cancelled
    },
    cancelledTitle() {
      if (this.event) {
        const reason = this.event.cancellation_reason || 'N/A'
        const time = this.event.cancelled_at || 'N/A'
        const by = this.event.cancelled_by || 'N/A'
        const fee_charged = this.event.cancellation_charged ? ', fee charged' : ''
        return 'Cancelled by ' + by + ' at ' + time + ', reason: ' + reason + fee_charged
      }
    },
    clientStatuses() {
      let statuses = []
      if (this.event.payment_status) {
        statuses.push({ css: 'text-' + this.event.payment_status_color, text: this.event.payment_status })
      }
      if (this.event.billable_status) {
        statuses.push({ css: 'text-' + this.event.billable_status_color, text: this.event.billable_status })
      }
      if (this.event.card_status) {
        statuses.push({ css: 'p-2 bg-' + this.event.card_status_color, text: this.event.card_status })
      }
      return statuses
    },
    hasJob() {
      return this.event.has_job
    },
    requireConfirmUnassign() {
      if (this.isClient) {
        return false
      }
      return this.hasJob
    },
    //// FIXME we only these different values to show different texts in the confirmation
    //// modal, but since the reservation is availabile there, we can easily simplify and
    //// remove this from here (and also from the event triggers and handles)
    confirmable() {
      if (this.canConfirm && this.pending) {
        if (this.event.isRecurring) {
          return 'pending-schedule'
        }
        return 'pending'
      }
    },
    editable() {
      return true
    },
    initial() {
      return this.event.initial
    },
    isRecurring() {
      return this.event && this.event.schedule != null
    },
    pending() {
      if (this.event) {
        return this.event.pending
      }
      return false
    },
    resendable() {
      return false
    },
    showAskSupportMessage() {
      return this.isClient && this.todayEvent
    },
    showClientInfo() {
      return this.event && (this.isCleaner || this.isStaff)
    },
    showTeamInfo() {
      return this.event && !this.loading && this.isStaff && !this.cancelling
    },
    updateable() {
      if (this.readonly) {
        return false
      }
      return this.isRecurring
    },
    locationId() {
      return this.event.location_id
    },
    showWidgets() {
      return !this.loading && this.rawShowWidgets
    },
    showCancel() {
      return this.showWidgets && this.canCancel && !this.cancelling
    }
  },
  data() {
    return {
      action: null,
      rawShowWidgets: true
    }
  },
  mounted() {
  },
  methods: {
    confirm() {
      if (this.event['has_unassigned_zip?']) {
        this.$emit('assign-area', this.event.id)
      } else {
        this.$emit('confirm', this.confirmable, this.event.id)
      }
    },
    edit() {
      this.$emit('edit', this.event)
    },
    markComplete() {},
    openCancelation(userType) {
      this.userType = userType
      this.action = 'cancel'
    },
    onAssigning() {
      this.rawShowWidgets = false
    },
    onCancelAssigning() {
      this.rawShowWidgets = true
    },
    onTestChanged() {
      this.$emit('reload-needed')
    },
    resend() {},
    sendWelcome() {
      self.loading = true
      this.$http.get('/reservations/' + this.event.id + '/welcome').then(response => {
        this.$flasher.success('Welcome email sent')
        self.loading = false
      })
    },
    update() {
      this.$emit('reschedule', this.event)
    }
  },
  watch: {
    action(new_value, old_value) {
      if (new_value != null) {
        if (new_value == 'sendWelcome') {
          if (this.canWelcome) {
             this.$dialog.confirm('Are you sure you want to send Welcome Email?', {
              okText: 'Yes',
              cancelText: 'No'
            })
            .then((dialog) => {
              this.sendWelcome()
            })
          } else {
            this.$flasher.error("Can't send welcome email")
          }
        }
        if (new_value == 'cancel') {
          return
        }
        if (new_value == 'edit') {
          if (this.editable) {
            this.$emit('edit', this.event)
          } else {
            this.$flasher.error("Can't edit")
          }
        }
        if (new_value == 'resend') {
          if (this.resendable) {
            this.resend()
          } else {
            this.$flasher.error("Can't resend")
          }
        }
        if (new_value == 'update') {
          if (this.updateable) {
            this.update()
          } else {
            this.$flasher.error("Can't update single visit")
          }
        }
        this.action = null
      }
    },
    editable(new_value, old_value) {
      //this.rerenderPicker()
    },
    updateable(new_value, old_value) {
      //this.rerenderPicker()
    },
    resendable(new_value, old_value) {
      //this.rerenderPicker()
    },
  }
}
</script>
