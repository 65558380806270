<template>
  <div class="col-sm-8 color-picker">
    <div v-if="edit" class="colors float-right">
      <div class="col">
        <div class="d-flex justify-content-center">
          <span v-for="color in colors" :key="color.id" class="badge badge-secondary" :class="{ active: color.id == currentColorIdx }" @click="changeColor(color.id)" :style="{ backgroundColor: color.hex }">&nbsp;&nbsp;&nbsp;</span>
        </div>
        <div class="d-flex justify-content-center">
          <div class="btn-group">
            <button @click="save" class="btn btn-mini btn-primary">Update Visit</button>
            <button @click="saveAll" class="btn btn-mini btn-green">Update All</button>
            <button @click="cancel" class="btn btn-mini btn-gray">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <span v-else @click="edit = true" class="badge badge-secondary float-right" :style="style">&nbsp;&nbsp;&nbsp;</span>
  </div>
</template>
<script>
import api from '@/clients/api'
import { getObjById } from '@/utils'
export default {
  data() {
    return {
      currentColorIdx: this.colorId,
      //// from the API
      /*
      colors: {
        1: "#a4bdfc",
        2: "#7ae7bf",
        3: "#dbadff",
        4: "#ff887c",
        5: "#fbd75b",
        6: "#ffb878",
        7: "#46d6db",
        8: "#e1e1e1",
        9: "#5484ed",
        10: "#51b749",
        11: "#dc2127"
      },
      */
      //// from the google calendar web page
      colors: [
        // tomato
        { id: 11, hex: '#D50000' },
        // flamingo
        { id: 4, hex: '#E67C73' },
        // tangerine
        { id: 6, hex: '#F4511E' },
        // banana
        { id: 5, hex: '#F6BF26' },
        // sage
        { id: 2, hex: '#33B679' },
        // basil
        { id: 10, hex: '#0B8043' },
        // peacock
        { id: 7, hex: '#039BE5' },
        // blueberry
        { id: 9, hex: '#3F51B5' },
        // lavender
        { id: 1, hex: '#7986CB' },
        // grape
        { id: 3, hex: '#8E24AA' },
        // graphite
        { id: 8, hex: '#616161' }
      ],
      edit: false
    }
  },
  props: {
    colorId: {
      type: Number
    },
    eventId: {
      type: Number,
      required: true
    }
  },
  computed: {
    params() {
      return {
        gcal_color_id: this.currentColorIdx
      }
    },
    style() {
      if (this.currentColorIdx) {
        return "background-color: " + getObjById(this.colors, this.currentColorIdx).hex
      }
    }
  },
  methods: {
    changeColor(newColorIdx) {
      this.currentColorIdx = newColorIdx * 1
    },
    cancel() {
      this.edit = false
      this.currentColorIdx = this.colorId
    },

    save() {
      this.edit = false
      api.updateReservation(this.eventId, this.params).then(response => {
        this.$emit('reservation-updated')
      }, response => {
        this.$flasher.error(response.body)
      })
    },
    saveAll() {
      this.edit = false
      api.updateReservationsFromThis(this.eventId, this.params).then(response => {
        this.$emit('reservation-updated')
      }, response => {
        this.$flasher.error(response.body)
      })
    },
  }
}
</script>
<style>
  .color-picker .badge {
    border-width: 2px;
    border-style: solid;
    &.active {
      border-color: #222;
    }
  }
</style>
