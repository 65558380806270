<template>
  <div class="box-wrapper px-2 mt-3 col-12">
    <visit-warning v-if="enabled" context="notes" :property-id="event.location_id"></visit-warning>
    <div class="aditional-notes px-4 my-4">
      <spinner :show="loading" :use-modal="false" :message="spinnerMessage"></spinner>
      <template v-if="note && event && !loading">
        <entry-note :note="note" :enabled="enabled" :reservation-id="event.id" :booking="false" :alarm-code="note.alarm_code" :gate-code="note.gate_code" :lockbox="note.lockbox" note-key="instructions" class="box-wrapper px-2 mt-3 col-12"></entry-note>
        <note :note="note" :enabled="enabled" :reservation-id="event.id" note-key="bedrooms" class="box-wrapper px-2 mt-3 col-12"></note>
        <note :note="note" :enabled="enabled" :reservation-id="event.id" note-key="bathrooms" class="box-wrapper px-2 mt-3 col-12"></note>
        <note :note="note" :enabled="enabled" :reservation-id="event.id" note-key="kitchen" class="box-wrapper px-2 mt-3 col-12"></note>
        <note :note="note" :enabled="enabled" :reservation-id="event.id" note-key="special" class="box-wrapper px-2 mt-3 col-12"></note>
      </template>
    </div>
  </div>
</template>
<script>
import Note from './note.vue'
import EntryNote from './entry_note.vue'
import Spinner from '@/shared/spinner.vue'
import VisitWarning from '@/shared/visit_warning.vue'
import api from '@/clients/api'
import permissions_mixin from '@/shared/permissions_mixin'
export default {
  props: {
    reservationId: {
      type: Number
    },
    event: {
      type: Object
    },
    forVisit: {
      type: Boolean,
      default: false
    },
    permissions: {
      type: Object
    },
  },
  components: { EntryNote, Note, VisitWarning, Spinner },
  mixins: [ permissions_mixin ],
  data() {
    return {
      loading: false,
      note: null,
      visitNoteId: null,
      editedNote: null,
      spinnerMessage: 'Loading'
    }
  },
  mounted() {
    this.loadNote()
  },
  computed: {
    cancelled() {
      return this.event && this.event.cancelled
    },
    enabled() {
      return this.canUpdate
    },
    noteId() {
      if (this.visitNoteId) {
        return this.visitNoteId
      }
      if (this.note) {
        return this.note.id
      }
      if (this.event) {
        if (this.event.note_id) {
          return this.event.note_id
        }
        return this.event.location_note_id
      }
    }
  },
  methods: {
    loadNote() {
      if (this.noteId) {
        this.loading = true
        api.getNote(this.noteId).then(response => {
          this.note = response.data.notes
          this.note.cancelled = this.cancelled
          this.editedNote = null
          this.loading = false
        })
      }
    },
    resetEdit() {
      this.note = null
      this.visitNoteId = null
      this.editedNote = null
      this.loading = false
      console.log("resetEdit")
      //this.$emit('reservation-updated')
      this.$emit('notes:updated')
    },
    saveNote(newNote) {
      this.loading = true
      this.spinnerMessage = 'Saving'
      var params = { note: newNote }
      if (this.forVisit) {
        params.reservation_id = this.event.id
      }
      if (this.event.note_id == null) {
        api.createNote().then(response => {
          console.log("created note", response.data)
          this.visitNoteId = response.data.notes.id
          api.saveNote(this.visitNoteId, params).then(response => {
            console.log("saved note", this.visitNoteId, response.data)
            api.updateReservation(this.event.id, { note_id: this.visitNoteId }).then(response => {
              console.log("updated visit", this.event.id, response.data, 'loadNote')
              this.$emit('notes:updated')
              this.loading = false
              this.loadNote()
            })
          }, (errors) => {
            this.resetEdit()
          })
        }, (errors) => {
          this.resetEdit()
        })
      } else {
        api.saveNote(this.noteId, params).then(response => {
          console.log("saved note", this.visitNoteId, response.data)
          this.$emit('notes:updated')
          this.loading = false
        }, response => {
          this.resetEdit()
        })
      }
    },
    setNoteEdited(noteKey) {
      this.editedNote = noteKey
    }
  }
}
</script>
