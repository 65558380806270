<template>
  <div
    v-if="event && show"
    class="px-2 tab-pane fade"
    id="meetup"
    role="tabpanel"
    aria-labelledby="meetup-tab"
  >
    <meet-up
      :job-id="jobId"
      :visit-id="visitId"
      :property-id="propertyId"
      :property-coords="propertyCoords"
      :zoom="12"
    ></meet-up>
  </div>
</template>
<script>
import Spinner from '@/shared/spinner.vue'
import MeetUp from '@/shared/meet_up_directions.vue'
import api from '@/admins/api'
import permissions_mixin from '@/shared/permissions_mixin'
export default {
  mixins: [permissions_mixin],
  props: {
    event: {},
    loading: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Spinner,
    MeetUp
  },
  computed: {
    jobId() {
      if (this.event) {
        return this.event.main_job_id
      }
    },
    propertyCoords() {
      if (this.event) {
        return this.event.position
      }
    },
    propertyId() {
      if (this.event) {
        return this.event.location_id
      }
    },
    readonly() {
      return this.locked
    },
    visitId() {
      if (this.event) {
        return this.event.id
      }
    },
  },
  watch: {
    loading(newValue) {
      if (newValue === false) {
        this.editMode = null
      }
    }
  }
}
</script>
