<script>
import EventDetails from './event_details.vue'
import EventMeetup from './event_meetup.vue'
import EventSummary from './event_summary.vue'
import EventUpdates from './event_updates.vue'
import JobPictures from './job_pictures.vue'
import AdvancedNotes from './notes.vue'
import Billing from './billing.vue'
import Reimbursement from './reimbursement.vue'
import FeedbackAndTipForClient from './feedback_and_tip_for_client.vue'
import InspectionReportForStaff from './inspection_report_for_staff.vue'
import StaffFeedback from './staff_feedback.vue'
import StaffTip from './staff_tip.vue'
import Dispute from './dispute.vue'
import ScheduleForm from '@/schedule/form_for_calendars.vue'
import ModeSwitcher from '@/shared/mode_switcher.vue'
import BaseModal from '@/modals/base_modal.vue'
import Spinner from '@/shared/spinner.vue'
import locking_mixin from '@/shared/locking_mixin'
import permissions_mixin from '@/shared/permissions_mixin'
import api from '@/clients/api'

export default {
  props: {
    event: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    requestModal: {
      type: Boolean,
      required: true
    }
  },
  mixins: [ locking_mixin, permissions_mixin ],
  components: { AdvancedNotes, BaseModal, Billing, Dispute, EventSummary, EventDetails, EventMeetup, EventUpdates, ScheduleForm, ModeSwitcher, FeedbackAndTipForClient, StaffFeedback, StaffTip, Spinner, JobPictures, Reimbursement, InspectionReportForStaff },
  computed: {
    // readonly is true when the visit is locked by someone else
    // in that case the permissions will be empty
    // TODO need to add this.readonlyProperty
    permissions() {
      if (this.event) {
        if (this.isCleaner) {
          return {
            canRequestChanges: this.permissionsFromVisit.canRequestChanges
          }
        }
        return this.permissionsFromVisit
      }
      if (this.readonly) {
      }
      return {}
    },
    permissionsFromVisit() {
      if (this.event) {
        return this.event.permissions
      }
    },
    referenceID() {
      if (this.event) {
        return this.event.reference_id
      }
    },
  },
  methods: {
    requestEta() {
      this.$emit('update:loading', true)
      api.requestETA(this.event.id).then(response => {
        this.$flasher.success('Success')
        this.$emit('update:loading', false)
        this.$emit('reload-needed')
      }, errors => {
        this.$emit('update:loading', false)
        this.$flasher.errors(errors.response.data.errors)
      })
    },
    sendEta() {
      this.$emit('update:loading', true)
      api.sendETA(this.event.id).then(response => {
        this.$flasher.success('Success')
        this.$emit('update:loading', false)
        this.$emit('reload-needed')
      }, errors => {
        this.$emit('update:loading', false)
        this.$flasher.errors(errors.response.data.errors)
      })
    }
  }
}
</script>
