<template>
  <div class="modal-body">
    <event-summary v-if="event" :event="event" :permissions="permissions" :readonly="readonly" @request-eta="requestEta" @send-eta="sendEta" />
      <div class="visit-modal-nav mt-0">
        <div>
          <ul class="nav justify-content-center request-tabs">
          <li class="nav-item"><a class="nav-link" id="details-tab" data-toggle="tab" href="#details" role="tablist" aria-controls="details-tab" aria-selected="false">Details</a></li>
          <li class="nav-item"><a @click.passive="onMeetupClick" class="nav-link" id="meetup-tab" data-toggle="tab" href="#meetup" role="tablist" aria-controls="meetup-tab" aria-selected="false">Meet Up</a></li>
          <li class="nav-item"><a class="nav-link" id="updates-tab" data-toggle="tab" href="#updates" role="tablist" aria-controls="updates-tab" aria-selected="false">Updates</a></li>
          <li class="nav-item"><a class="nav-link" id="pictures-tab" data-toggle="tab" href="#pictures" role="tablist" aria-controls="pictures-tab" aria-selected="false">Pictures</a></li>
          <li class="nav-item"><a class="nav-link" id="notes-tab" data-toggle="tab" href="#notes" role="tablist" aria-controls="notes-tab" aria-selected="false">Notes</a></li>
          <li v-if="isStaff && !requestModal" class="nav-item"><a class="nav-link" id="reimbursement-tab" data-toggle="tab" href="#reimbursement" role="tablist" aria-controls="reimbursement-tab" aria-selected="false">Reimbursement & Bonus Perk</a></li>
          <li v-if="!requestModal" class="nav-item"><a class="nav-link" id="billing-tab" data-toggle="tab" href="#billing" role="tablist" aria-controls="billing-tab" aria-selected="false">Billing</a></li>
          <li v-if="showDispute" class="nav-item"><a class="nav-link" id="dispute-tab" data-toggle="tab" href="#dispute" role="tablist" aria-controls="dispute-tab" aria-selected="false">Dispute</a></li>
          <li v-if="!requestModal && isStaff" class="nav-item"><a class="nav-link" id="tip-tab" data-toggle="tab" href="#tip" role="tablist" aria-controls="tip-tab" aria-selected="false">Give Tip</a></li>
          <li v-if="!requestModal" class="nav-item"><a class="nav-link" id="feedback-tab" data-toggle="tab" href="#feedback" role="tablist" aria-controls="feedback-tab" aria-selected="false">Feedback</a></li>
          <li v-if="!requestModal && isStaff" class="nav-item"><a class="nav-link" id="inspection-tab" data-toggle="tab" href="#inspection" role="tablist" aria-controls="inspection-tab" aria-selected="false">Inspection Report</a></li>
        </ul>
      </div>
    </div>
    <div class="mt-3 px-0 col-12">
      <div class="tab-content">
        <div class="px-2 tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
          <request-changes-button v-if="canRequestChanges && !requestModal" :requesting-changes="requestingChanges" :wants-request-changes="wantsRequestChanges" :has-pending-request="hasPendingRequest" @update="onRequestingChangesUpdate" />
          <event-details :event="event" @request-changes="onChangesRequested" :locked="notEditable" :requesting-changes="requestingChanges" :permissions="permissions" :loading="loading" />
        </div>
        <event-meetup :event="event" :loading="loading" :locked="readonly" :show="showMeetup" />
        <div class="px-2 tab-pane fade" id="updates" role="tabpanel" aria-labelledby="updates-tab">
          <event-updates :visit="event" :main-loading="loading" @close="$emit('close')" :locked="readonly" />
        </div>
        <div class="px-2 tab-pane fade" id="pictures" role="tabpanel" aria-labelledby="pictures-tab">
          <job-pictures :visit="event" @close="$emit('close')" :locked="readonly" />
        </div>
        <div class="px-2 tab-pane fade" id="notes" role="tabpanel" aria-labelledby="notes-tab">
          <advanced-notes :event="event" :for-visit="true" :locked="readonly" />
        </div>
        <div v-if="isStaff && !requestModal" class="px-2 tab-pane fade" id="reimbursement" role="tabpanel" aria-labelledby="reimbursement-tab">
          <reimbursement :event-id="event.id" @reload-event="$emit('close')" />
        </div>
        <div v-if="!requestModal" class="px-2 tab-pane fade" id="billing" role="tabpanel" aria-labelledby="billing-tab">
          <billing :event-id="event.id" />
        </div>
        <div v-if="showDispute" class="px-2 tab-pane fade" id="dispute" role="tabpanel" aria-labelledby="dispute-tab">
          <dispute :event-id="event.id" @update:loading="$emit('update:loading', $event)" :loading="loading" />
        </div>
        <div v-if="!requestModal && isStaff" class="px-2 tab-pane fade" id="tip" role="tabpanel" aria-labelledby="tip-tab">
          <staff-tip
            :event-id="event.id"
            :loading="loading"
            @update:loading="$emit('update:loading', $event)"
          />
        </div>
        <div class="px-2 tab-pane fade" id="feedback" role="tabpanel" aria-labelledby="feedback-tab">
          <staff-feedback
            v-if="isStaff"
            :event-id="event.id"
            :loading="loading"
            @update:loading="$emit('update:loading', $event)"
          />
          <feedback-and-tip-for-client
            v-if="isClient"
            :event-id="event.id"
            :loading="loading"
            @update:loading="$emit('update:loading', $event)"
          />
        </div>
        <div v-if="showInspection" class="px-2 tab-pane fade" id="inspection" role="tabpanel" aria-labelledby="inspection-tab">
          <inspection-report-for-staff :event-id="event.id" @update:loading="$emit('update:loading', $event)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Base from './base.vue'
import RequestChangesButton from './request_changes_button.vue'
import api from '@/clients/api'
export default {
  extends: Base,
  components: { RequestChangesButton },
  props: {
    loadEventFunc: {
      type: Function,
      required: true
    }
  },
  computed: {
    showDispute() {
      return !this.requestModal && this.event && this.event.hasDispute
    },
    showInspection() {
        return this.isStaff
    },
    hasPendingRequest() {
      return this.event && this.event.hasPendingRequest
    },
    notEditable() {
      return this.readonly || !this.requestingChanges
    }
  },
  data() {
    return {
      requestingChanges: false,
      showMeetup: false,
      wantsRequestChanges: false
    }
  },
  mounted() {
    $('.request-tabs a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
      this.requestingChanges = false
    })
  },
  methods: {
    onMeetupClick() {
      this.showMeetup = true
    },
    onRequestingChangesUpdate({ requestingChanges, wantsRequestChanges }) {
      this.wantsRequestChanges = wantsRequestChanges
      if (wantsRequestChanges === true) {
        this.loadEventFunc(this.event.id, () => {
          this.requestingChanges = !this.hasPendingRequest
        })
      }
    },
    // can be moved into the event modal since we can close the modal after requseting the changes
    onChangesRequested(params) {
      this.requestingChanges = false
      this.$emit('update:loading', true)
      api.reservationChangesRequested(this.event.id, params).then(response => {
        this.$flasher.success('Success')
        this.$emit('reload-needed')
        this.$emit('change:tab', 'updates')
      }, errors => {
        this.$emit('update:loading', false)
        this.$flasher.errors(errors.response.data.errors)
      })
    },
    onEditSummary() {
      console.log("onEditSummary")
    },
    onEditModeChange(e) {
      console.log("RequestChanges onEditModeChange", e)
    }
  }
}
</script>
