<template>
  <my-form :start-in-edit-mode="true">
    <template v-slot:default="{invalid, validated, valid, errors, formIsReadonly }">
      <h5 class="mb-5">Please tell us why you want to mark this job as Call Off</h5>
      <spinner :show="saving" message="Saving"></spinner>
      <div class="ui error message" v-if="invalid && validated">
        <b>Please correct the errors below:</b>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-6">
          <my-dropdown v-model="main_reason" :options="reasons" rules="min:3"  css-class=""></my-dropdown>
        </div>
      </div>
      <template v-if="main_reason == ''">
        <div class="row justify-content-center align-items-center">
          <div class="col-sm-6">
            <my-text v-model="calloff_reason" name="Reason" rules="required|min:3"></my-text>
          </div>
        </div>
      </template>
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-6 text-center">
          <b-form-group :label="chargeLabel">
            <b-form-radio-group id="charge-cleaner" v-model="charge_cleaner">
              <b-form-radio value="true">Yes</b-form-radio>
              <b-form-radio value="false">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </template>
    <template v-slot:edit-buttons="{ invalid, validated }">
      <div class="btn-set mb-0 mt-4 justify-content-center align-items-center">
        <b-button :disabled="invalid && validated" @click="doCancel" variant="primary">Call Off</b-button>
        <b-button @click="$emit('cancel')" variant="red">Do not Call Off</b-button>
      </div>
    </template>
  </my-form>
</template>
<script>
import api from '@/admins/api'
import Spinner from '@/shared/spinner.vue'
import MyForm from '@/shared/base_form.vue'
import MyDropdown from '@/shared/inputs/select.vue'
import MyText from '@/shared/inputs/text.vue'
export default {
  props: {
    jobId: {
      type: Number,
      required: true
    },
    callOffAmount: {
      type: String,
      required: true
    }
  },
  components: { MyText, MyForm, MyDropdown, Spinner },
  data() {
    return {
      saving: false,
      main_reason: 'Unresponsive',
      calloff_reason: null,
      charge_cleaner: true,
      reasons: [
        { label: 'Unresponsive', value: 'Unresponsive' },
        { label: 'Not on the way', value: 'Not on the way' },
        { label: 'Client reported a non-arrival', value: 'Non-arrival' },
        { label: 'Cleaner request to cancel Claimed/Accepted Job', value: 'Cleaner request' },
        { label: 'Other', value: '' }
      ]
    }
  },
  computed: {
    chargeLabel() {
      return "Would you like to charge a " + this.callOffAmount + " cancelation?"
    }
  },
  methods: {
    doCancel() {
      this.submit()
    },
    doNotCancel() {
      this.$emit('do-not-cancel')
    },
    submit(do_not_notify = true) {
      const params = {
        cancel_reason: this.main_reason.length > 0 ? this.main_reason : this.calloff_reason,
        charge_cleaner: this.charge_cleaner, notify_cleaner: !do_not_notify
      }
      this.saving = true
      return new Promise((resolve, reject) => {
        api.callOffCleaningJob(this.jobId, params).then(response => {
          this.$emit('done')
          return resolve()
        }, (errors) => {
          this.$flasher.errors(errors.response.data.errors)
          this.saving = false
          return reject(errors)
        })
      })
    }
  }
}
</script>
