<template>
  <div v-if="event" class="row">
    <div class="col-sm-12">
      <h4 class="timeline-title" :class="statusClass">{{title}}</h4>
      <div class="row alert client-info">
        <div class="col-sm-auto">
          <p class="m-0">Client: <span v-html="linkedName"></span></p>
        </div>
        <template v-if="showContact">
          <div class="col-sm-auto">
            <p class="m-0">Email: <a :href="'mailto:'+email">{{email}}</a></p>
          </div>
          <div class="col-sm-auto">
            <p class="mb-2">Phone: <a :href="'tel:'+phone">{{phone}}</a></p>
          </div>
        </template>
      </div>
    </div>
    <div v-if="showRequestEta" class="col-sm-12">
      <a v-if="canRequestEta" href="#" @click="$parent.$emit('send-eta')">Request ETA</a>
      <small v-if="etaRequestedAgo">{{etaRequestedAgo}}</small>
      <span v-if="showEta" class="orange">{{ onMyWayInfo }}</span>
      <span v-if="showDetectedEta" class="orange">{{ detectedEtaInfo }}</span>
    </div>
  </div>
</template>
<script>
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)
export default {
  props: {
    event: {
      type: Object
    },
    canRequestEta: {
      type: Boolean,
      default: false
    },
    showRequestEta: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    email() {
      return this.event.client_email
    },
    etaRequestedAgo() {
      if (this.canRequestEta && this.event.eta_requested_at) {
        const timeAgo = new TimeAgo('en-US')
        return 'ETA last requested at ' + timeAgo.format(new Date(this.event.eta_requested_at))
      }
    },
    hasEta() {
      if (this.event && this.event.on_my_way_info) {
        return !!this.event.on_my_way_info.eta
      }
    },
    hasDetectedEta() {
      if (this.event && this.event.on_my_way_info) {
        return this.event.on_my_way_info.detected
      }
    },
    showEta() {
      if (this.isClient && this.hasEta && this.event.eta_requested_at) {
        return true
      }
      if (this.isStaff) {
        return this.hasEta && !this.hasDetectedEta
      }
    },
    showDetectedEta() {
      return this.hasDetectedEta && this.isStaff
    },
    hasOnMyWayInfo() {
      if (this.event) {
        return !!this.event.on_my_way
      }
    },
    onMyWayInfo() {
      let fullStatus = ''
      if (this.hasOnMyWayInfo) {
        fullStatus += "The cleaner is on the way!"
      }
      if (this.hasEta) {
        fullStatus += " ETA " + this.eta
        if (this.isStaff && this.distance) {
          fullStatus += ' (' + this.distance + ')'
        }
      }
      return fullStatus
    },
    detectedEtaInfo() {
      let info = ''
      if (this.hasEta) {
        info += " ETA (detected) " + this.eta
        if (this.isStaff && this.distance) {
          info += ' (' + this.distance + ')'
        }
      }
      return info
    },
    eta() {
      if (this.hasEta) {
        return this.event.on_my_way_info.eta
      }
    },
    distance() {
      if (this.hasEta) {
        return this.event.on_my_way_info.distance
      }
    },
    linkedName() {
      if (this.isStaff) {
        return '<a href="/admin/clients/' + this.event.client_id + '" target="_blank">' + this.name + '</a>'
      }
      return this.name
    },
    name() {
      return this.event.client_name
    },
    phone() {
      return this.event.client_phone
    },
    title() {
      return this.event.selected_plan
    },
    showContact() {
      return this.isStaff
    },
    //// deep -> Green, basic -> Blue, confirmation -> Yellow -->
    statusClass() {
      if (this.event) {
        return this.event.status_color + '-text'
      }
    },
  }
}
</script>
