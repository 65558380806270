<template>
  <div class="box-wrapper p-3">
    <div class="row" :class="colorCss">
      <div class="col-sm">
        <p>{{actor}} <span v-html="details"></span><span v-if="showSameDayBadge" class="ml-2 badge badge-pill badge-danger">Same Day Change</span></p>
        <small>{{info.at}}</small>
      </div>
    </div>
  </div>
</template>
<script>
import BaseEvent from '../base_event.vue'

export default {
  extends: BaseEvent,
  props: {
    domId: {
      type: String,
      required: true
    },
    prefixText: {
      type: String
    },
    suffixText: {
      type: String
    },
    visit: {
      type: Object,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    },
    showSameDayBadge: {
      type: Boolean,
      default: false
    }
  }
}
</script>
