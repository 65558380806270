<template>
  <div class="box-wrapper p-3">
    <div class="row">
      <div class="col-sm">
        {{details}} at <small>{{info.at}} by {{actor}}</small>
      </div>
    </div>
  </div>
</template>
<script>
import BaseEvent from '../base_event.vue'

export default {
  extends: BaseEvent,
  props: {
    domId: {
      type: String,
      required: true
    },
    prefixText: {
      type: String
    },
    suffixText: {
      type: String
    },
    visit: {
      type: Object,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    }
  }
}
</script>
